import { CloudinaryImageResponse, HelpPromptType, HelpTourType, PlayerBalanceType, PlayerTagType } from "../../objectTypes";
import { MMASeasonSummaries } from "../../objectTypes/MMA/SeasonsSummary";
import { CategoryProps, FilterProps, LeaderProps, MetricsProps, TimerangeProps } from "../types/analytics_svc";
import { AppAlertProps, CharityProps, CodeRequestProps, CodeRequestStats2Props, CodeRequestStatsProps, LinkProps, LocationProps, MyPlayerProps, PlayerNotificationProps, PlayerPremiumItem, PlayerPushSubscriptionProps, PlayerReferralProps, PlayerSettingProps, PlayerVouchTokenProps, PollCampaignGoalProps, PollCampaignLeaderProps, PollCampaignProps, PollOptionProps, PollProps, PollSummaryProps, PremiumItemProps, PromoProps, PublicPlayerProps, RewardOptionProps, SettingProps } from "../types/auth_svc"
import { AlertProps } from "../types/local";
import { MarketProps, OrderProps, PositionProps, TradeProps, OrderStatsProps, EventOrderStatProps, OrderBookProps, PlayerFilterProps, HedgeProps, MarketResolveProps, BestAvailableOrderProps } from "../types/mk_svc";
import { ItemOrderProps } from "../types/pay_svc";
import { ArticleFeedProps, ArticleProps, CompanyAdProps, GroupMessageProps, GroupPlayerProps, GroupProps, PlayerCouponProps, PlayerFavoriteProps, PlayerFollowerProps, PlayerFollowerStatsProps, PlayerLinkProps, PlayerLocationProps, PlayerTagProps, PodcastEpisodeProps, PodcastProps, PostCommentProps, PostCommentStatProps, PostProps, PostReactionProps, PostReactionStatsProps, TagProps } from "../types/soc_svc";
import { AthleteProps, BracketGroupProps, BracketProps, BracketRoundProps, EventProps, EventScheduleProps, ExoticProps, ExternalPriceProps, GolfCourseProps, GolfLeaderProps, GolfScorecardProps, GolfTournamentStatisticsProps, LeagueProps, MatchProps, RoundEventProps, TeamProps, TournamentProps, TournamentScheduleProps } from "../types/sr_svc";
import { BracketCompetitionProps, BracketCompetitionScoringRuleProps, CompetitionMatchMarketProps, CompetitionMatchProps, CompetitionPayoutTypeProps, CompetitionPlayerBracketProps, CompetitionPlayerProps, CompetitionProps, CompetitionRecordProps, CompetitionResultProps, CompetitionResultTypeProps, CompetitionSeasonProps, CompetitionSeasonResultProps, CompetitionTypeProps, CreateCompetitionProps, PlayerBracketPickProps, PlayerBracketProps, PlayerPickProps, PlayerSquareProps, SquareOfferProps, SquareProps, SquareResultProps, SquaresCompetitionProps, SquaresPayoutProps } from "../types/tp_svc";


//Authentication Actions and Reducers
export type AuthReducerProps = {
    isAuthenticated: boolean;
    market_type:'FREE'|'FOR_MONEY'
    account:any;
    no_help?:boolean,
    default_lines?:'best_available'|'last_trade'|'best_available_grades';
    access_token?:string;
    device_id?:string;
    show_unique_orders?:boolean,
    hidden_alerts:string[];
    refresh_token?:string;
    expire_datetime?:string;
    notification_id?:string;
    notification?:PlayerNotificationProps
}

export const V1_LOGIN = 'V1_LOGIN';
export const V1_LOGOUT = 'V1_LOGOUT';
export const V1_SHOW_UNIQUE_ORDERS = 'V1_SHOW_UNIQUE_ORDERS'
export const V1_UPDATE_NO_HELP = 'V1_UPDATE_NO_HELP';
export const V1_UPDATE_DEFAULT_LINES = 'V1_UPDATE_DEFAULT_LINES';
export const V1_UPDATE_ACCOUNT = 'V1_UPDATE_ACCOUNT';
export const V1_UPDATE_MARKET_TYPE = 'V1_UPDATE_MARKET_TYPE';
export const V1_HIDE_ALERT = 'V1_HIDE_ALERT';
export const V1_LOAD_NOTIFICATION_ID = 'V1_LOAD_NOTIFICATION_ID';
export const V1_LOAD_INIT_NOTIFICATION = 'V1_LOAD_INIT_NOTIFICATION';

//Player Actions And Reducers
export type PlayerReducerProps = {
    player?: MyPlayerProps;
    player_balance?: PlayerBalanceType;
    player_favorites: PlayerFavoriteProps[]
    player_referral?: PlayerReferralProps;
    reward_option?: RewardOptionProps;
    code_request_stats?:CodeRequestStats2Props,
    promo?:PromoProps,
    player_premium_items_list: {
        updated:number,
        player_premium_items:PlayerPremiumItem[]
    }
    premium_items: PremiumItemProps[],
    viewing_player_referral?: PlayerReferralProps,
    player_vouch_token?:PlayerVouchTokenProps,
    viewing_referrer?:PublicPlayerProps,
    code_request?:CodeRequestProps,
    player_notification_count:number,
    code_requests_list: {
        updated:number,
        code_requests: CodeRequestProps[]
    },
    viewing_promo?: PromoProps,
    player_notifications: PlayerNotificationProps[];
    player_settings_list: {
        updated: number,
        player_settings: PlayerSettingProps[];
    }
    settings: SettingProps[];
    hedges: {
        updated:number,
        hedges: HedgeProps[],
        orders: OrderProps[]
    }
    orders: {
        updated: number,
        orders: OrderProps[]
    };
    player_push_subscriptions_list: {
        updated:number,
        player_push_subscriptions: PlayerPushSubscriptionProps[]
    },
    resolved_orders: OrderProps[];
    h2h_requests: OrderProps[];
    alert_order?: OrderProps;
    player_coupons: PlayerCouponProps[];
    charities: CharityProps[];
    alerts:AlertProps[]
} 

export const V1_UPDATE_ME = 'V1_UPDATE_ME';
export const V1_UPDATE_PLAYER_NOTIFICATION_COUNT = 'V1_UPDATE_PLAYER_NOTIFICATION_COUNT';
export const V1_UPDATE_PLAYER_BALANCE = 'V1_UPDATE_PLAYER_BALANCE';
export const V1_LOAD_PLAYER_SETTINGS = 'V1_LOAD_PLAYER_SETTINGS';
export const V1_LOAD_PREMIUM_ITEMS = 'V1_LOAD_PREMIUM_ITEMS';
export const V1_LOAD_PLAYER_PREMIUM_ITEMS = 'V1_LOAD_PLAYER_PREMIUM_ITEMS';
export const V1_UPDATE_PLAYER_SETTING = 'V1_UPDATE_PLAYER_SETTING';
export const V1_LOAD_SETTINGS = 'V1_LOAD_SETTINGS';
export const V1_ADD_PLAYER_NOTIFICATIONS = 'V1_ADD_PLAYER_NOTIFICATIONS';
export const V1_UPDATE_PLAYER_NOTIFICATION = 'V1_UPDATE_PLAYER_NOTIFICATION';
export const V1_CLEAR_PLAYER_NOTIFICATIONS = 'V1_CLEAR_PLAYER_NOTIFICATIONS';
export const V1_LOAD_PLAYER_REFERRAL = 'V1_LOAD_PLAYER_REFERRAL';
export const V1_LOAD_VIEWING_REFERRAL = 'V1_LOAD_VIEWING_REFERRAL'
export const V1_LOAD_PLAYER_FAVORITES = 'V1_LOAD_PLAYER_FAVORITES';
export const V1_LOAD_PLAYER_ORDERS = 'V1_LOAD_PLAYER_ORDERS';
export const V1_LOAD_PLAYER_HEDGES = 'V1_LOAD_PLAYER_HEDGES';
export const V1_LOAD_RESOLVED_ORDERS = 'V1_LOAD_RESOLVED_ORDERS';
export const V1_LOAD_H2H_REQUESTS = 'V1_LOAD_H2H_REQUESTS';
export const V1_UPDATE_PLAYER_ORDER = 'V1_UPDATE_PLAYER_ORDER';
export const V1_UPDATE_PLAYER_HEDGE = 'V1_UPDATE_PLAYER_HEDGE';
export const V1_UPDATE_PROCESSED_HEDGE = 'V1_UPDATE_PROCESSED_HEDGE';
export const V1_LOAD_ALERT_ORDER = 'V1_LOAD_ALERT_ORDER';
export const V1_LOAD_CHARITIES = 'V1_LOAD_CHARITIES';
export const V1_LOAD_ALERT = 'V1_LOAD_ALERT';
export const V1_CLEAR_ALERT = 'V1_CLEAR_ALERT';
export const V1_LOAD_PLAYER_VOUCH_TOKEN = 'V1_LOAD_PLAYER_VOUCH_TOKEN';
export const V1_UPDATE_ORDER_SUGGESTIONS = 'V1_UPDATE_ORDER_SUGGESTIONS';
export const V1_LOAD_CODE_REQUEST_STATS = 'V1_LOAD_CODE_REQUEST_STATS';
export const V1_LOAD_CODE_REQUESTS = 'V1_LOAD_CODE_REQUESTS';
export const V1_LOAD_PLAYER_COUPONS = 'V1_LOAD_PLAYER_COUPONS';
export const V1_UPDATE_PLAYER_COUPON = 'V1_UPDATE_PLAYER_COUPON';
export const V1_LOAD_PLAYER_PUSH_SUBSCRIPTIONS = 'V1_LOAD_PLAYER_PUSH_SUBSCRIPTIONS';
export const V1_UPDATE_PLAYER_PUSH_SUBSCRIPTION = 'V1_UPDATE_PLAYER_PUSH_SUBSCRIPTION';



//Social Actions And Reducers
export type SocialReducerProps = {
    viewing_player_followers: PlayerFollowerProps[];
    //viewing_post?:PostProps;
    stored_posts: PostProps[];
    stored_post_comments: PostCommentProps[];
    viewing_player_follower_stats?: PlayerFollowerStatsProps;
    viewing_player_posts: string[];
    viewing_player_orders: OrderProps[];
    viewing_orders:OrderProps[];
    viewing_player_tags: PlayerTagProps[];
    viewing_player_links: PlayerLinkProps[];
    recent_podcasts: string[];
    recent_articles: string[];
    stored_podcasts: PodcastProps[];
    articles_list: {
        updated:number,
        articles: ArticleProps[]
    }
    stored_articles:ArticleProps[];
    stored_article_feeds:ArticleFeedProps[];
    playing_podcast_episode?: PodcastEpisodeProps;
    viewing_episodes: PodcastEpisodeProps[];
    tags: TagProps[];
    group?:GroupProps;
    group_messages:GroupMessageProps[];
    group_players:GroupPlayerProps[];
    stored_players: PublicPlayerProps[];
    suggested_players: string[];
    searched_players: string[];
    new_players: string[];
    player_tags: PlayerTagProps[];
    links: LinkProps[];
    player_links: PlayerLinkProps[];
    feed_posts: string[];
    live_feeds_list: {
        updated:number,
        article_feeds:ArticleFeedProps[]
    }
    full_size_image?:CloudinaryImageResponse;
    hot_posts: string[];
    stored_ads: CompanyAdProps[];
    player_followers: PlayerFollowerProps[];
    post_reaction_stats: PostReactionStatsProps[];
    post_comment_stats: PostCommentStatProps[];
    my_post_reactions: PostReactionProps[];
}

export const V1_LOAD_PLAYERS = 'V1_LOAD_PLAYERS';
export const V1_LOAD_SUGGESTED_PLAYERS = 'V1_LOAD_SUGGESTED_PLAYERS';
export const V1_LOAD_SEARCHED_PLAYERS = 'V1_LOAD_SEARCHED_PLAYERS';
export const V1_LOAD_NEW_PLAYERS = 'V1_LOAD_NEW_PLAYERS';
export const V1_LOAD_VIEWING_FOLLOWER_STATS = 'V1_LOAD_VIEWING_FOLLOWER_STATS';
export const V1_LOAD_VIEWING_PLAYER_POSTS = 'V1_LOAD_VIEWING_PLAYER_POSTS';
export const V1_LOAD_POST_COMMENTS = 'V1_LOAD_POST_COMMENTS';
export const V1_UPDATE_POST_COMMENTS = 'V1_UPDATE_VIEWING_POST_COMMENTS';
export const V1_LOAD_FEED_POSTS = 'V1_LOAD_FEED_POSTS';
export const V1_LOAD_HOT_POSTS = 'V1_LOAD_HOT_POSTS';
export const V1_LOAD_PLAYER_TAGS = 'V1_LOAD_PLAYER_TAGS';
export const V1_UPDATE_PLAYER_TAG = `V1_UPDATE_PLAYER_TAG`;
export const V1_LOAD_VIEWING_PLAYER_TAGS = 'V1_LOAD_VIEWING_PLAYER_TAGS';
export const V1_UPDATE_PLAYER_FOLLOWERS = 'V1_UPDATE_PLAYER_FOLLOWERS';
export const V1_UPDATE_POST_REACTION_STATS = 'V1_UPDATE_POST_REACTION_STATS';
export const V1_LOAD_MY_POST_REACTIONS = 'V1_LOAD_MY_POST_REACTIONS';
export const V1_UPDATE_MY_POST_REACTIONS = 'V1_UPDATE_MY_POST_REACTIONS';
export const V1_LOAD_VIEWING_PLAYER_FOLLOWERS = 'V1_LOAD_VIEWING_PLAYER_FOLLOWERS';
export const V1_LOAD_VIEWING_PLAYER_ORDERS = 'V1_LOAD_VIEWING_PLAYER_ORDERS';
export const V1_UPDATE_ARTICLES = 'V1_UPDATE_ARTICLES'
export const V1_LOAD_TAGS = 'V1_LOAD_TAGS';
export const V1_LOAD_LINKS = 'V1_LOAD_LINKS';
export const V1_LOAD_PLAYER_LINKS = 'V1_LOAD_PLAYER_LINKS'
export const V1_LOAD_VIEWING_PLAYER_LINKS = 'V1_LOAD_VIEWING_PLAYER_LINKS';
export const V1_UPDATE_PLAYER_LINK = 'V1_UPDATE_PLAYER_LINK'
export const V1_LOAD_GROUP = 'V1_LOAD_GROUP';
export const V1_UPDATE_GROUP_MESSAGE = 'V1_UPDATE_GROUP_MESSAGE';
export const V1_UPDATE_POST_COMMENT_STAT = 'V1_UPDATE_POST_COMMENT_STAT';
export const V1_UPDATE_POST = 'V1_UPDATE_POST';
export const V1_LOAD_VIEWING_ORDERS = 'V1_LOAD_VIEWING_ORDERS';
export const V1_LOAD_VIEWING_EPISODES = 'V1_LOAD_VIEWING_EPISODES';
export const V1_LOAD_PODCASTS = 'V1_LOAD_PODCASTS';
export const V1_LOAD_ARTICLES = 'V1_LOAD_ARTICLES';
export const V1_LOAD_ARTICLE_FEEDS = 'V1_LOAD_ARTICLE_FEEDS';
export const V1_LOAD_RECENT_PODCASTS = 'V1_LOAD_RECENT_PODCASTS';
export const V1_LOAD_RECENT_ARTICLES = 'V1_LOAD_RECENT_ARTICLES'
export const V1_UPDATE_PODCAST = 'V1_UPDATE_PODCAST';
export const V1_UPDATE_PLAYING_PODCAST = 'V1_UPDATE_PLAYING_PODCAST';
export const V1_UPDATE_AD = 'V1_UPDATE_AD';
export const V1_LOAD_LIVE_FEEDS = 'V1_LOAD_LIVE_FEEDS';
export const V1_UPDATE_LIVE_FEED = 'V1_UPDATE_LIVE_FEED';
export const V1_LOAD_FULL_SIZE_IMAGE = 'V1_LOAD_FULL_SIZE_IMAGE';


//Event Actions and Reducers
export type EventReducerProps = {
    leagues: LeagueProps[],
    active_event_ids: string[],
    popular_event_ids:string[],
    active_tournament_ids: string[]
    schedule_range: string[],
    events: {
        [key:string]: EventProps
    },
    tournaments: {
        [key:string]: TournamentProps
    }
    matches: {
        [key: string]: MatchProps
    },
    teams_list: {
        updated:number,
        teams:TeamProps[]
    },
    athletes_list:{
        updated: number,
        athletes: AthleteProps[]
    },
    brackets_list: {
        updated:number,
        bracket_id:string,
        bracket: BracketProps,
        bracket_groups:BracketGroupProps[],
        bracket_rounds: BracketRoundProps[],
        round_events: RoundEventProps[]
    }[],
    exotics_list: {
        updated:number,
        exotics:ExoticProps[]
    },
    mma_details_list: {
        updated: number,
        mma_details: MMASeasonSummaries['summaries']
    },
    golf_courses_list: {
        updated:number,
        golf_courses: GolfCourseProps[]
    },
    golf_leaders_list: {
        updated:number,
        golf_leaders:GolfLeaderProps[]
    },
    golf_scorecards_list: {
        updated:number,
        golf_scorecards: GolfScorecardProps[]
    },
    golf_tournament_statistics_list: {
        updated:number,
        golf_tournament_statistics: GolfTournamentStatisticsProps[]
    }
    latest_prices:ExternalPriceProps[];
    prices:ExternalPriceProps[];
    prices_list: {
        updated:number,
        external_prices: ExternalPriceProps[]
    }
}

export const V1_LOAD_LEAGUES = 'V1_LOAD_LEAGUES';
export const V1_LOAD_ACTIVE_EVENT_IDS = 'V1_LOAD_ACTIVE_EVENT_IDS';
export const V1_LOAD_POPULAR_EVENT_IDS = 'V1_LOAD_POPULAR_EVENT_IDS';
export const V1_LOAD_ACTIVE_TOURNAMENT_IDS = 'V1_LOAD_ACTIVE_TOURNAMENT_IDS';
export const V1_LOAD_LEAGUE_SCHEDULE = 'V1_LOAD_LEAGUE_SCHEDULE';
export const V1_LOAD_TOURNAMENT_SCHEDULE = 'V1_LOAD_TOURNAMENT_SCHEDULE';
export const V1_CLEAR_SCHEDULE_RANGE = 'V1_CLEAR_SCHEDULE_RANGE';
export const V1_UPDATE_EVENT = 'V1_UPDATE_EVENT';
export const V1_LOAD_EVENTS = 'V1_LOAD_EVENTS';
export const V1_LOAD_TEAMS = 'V1_LOAD_TEAMS';
export const V1_UPDATE_TEAM = 'V1_UPDATE_TEAM';
export const V1_LOAD_ATHLETES = 'V1_LOAD_ATHLETES';
export const V1_UPDATE_EXOTIC = 'V1_UPDATE_EXOTIC';
export const V1_UPDATE_ATHLETE = 'V1_UPDATE_ATHLETE';
export const V1_LOAD_TOURNAMENTS = 'V1_LOAD_TOURNAMENTS';
export const V1_UPDATE_MMA_DETAILS = 'V1_UPDATE_MMA_DETAILS';
export const V1_UPDATE_GOLF_LEADERS = 'V1_UPDATE_GOLF_LEADERS';
export const V1_UPDATE_GOLF_COURSES = 'V1_UPDATE_GOLF_COURSES';
export const V1_UPDATE_GOLF_SCORECARDS = 'V1_UPDATE_GOLF_SCORECARDS';
export const V1_UPDATE_GOLF_TOURNAMENT_STATISTICS = 'V1_UPDATE_GOLF_TOURNAMENT_STATISTICS';
export const V1_UPDATE_TOURNAMENT = 'V1_UPDATE_TOURNAMENT';
export const V1_LOAD_MATCHES = 'V1_LOAD_MATCHES';
export const V1_UPDATE_MATCH = 'V1_UPDATE_MATCH';
export const V1_LOAD_LATEST_PRICES = 'V1_LOAD_LATEST_PRICES'
export const V1_LOAD_PRICES = 'V1_LOAD_PRICES';
export const V1_LOAD_ORDER_PRICES = 'V1_LOAD_ORDER_PRICES';
export const V1_UPDATE_ORDER_PRICES = 'V1_UPDATE_ORDER_PRICES';
export const V1_LOAD_BRACKETS = 'V1_LOAD_BRACKETS';
export const V1_UPDATE_BRACKET = 'V1_UPDATE_BRACKET';

//Market Actions and Reducers
export type MarketReducerProps = {
    markets: MarketProps[];
    trades: TradeProps[];
    orders: OrderProps[];
    orders_lists: {
        event_id:string,
        event_type:string,
        updated:number,
        orders:OrderProps[]
    }[],
    best_available: {
        [key:string]: BestAvailableOrderProps[] //Key will be event_type:id
    },
    order_stats: {
        [key:string]: EventOrderStatProps[] //Key will be event_type:id
    },
    latest_trades: {
        [key:string]: TradeProps[]  //key will be event_type:id
    }
    external_price_comparison:{
        event_id?:string,
        event_type?:string,
        init_market?:MarketProps,
        participant_type?:string,
        participant_id?:string
    },
    market_resolve?: MarketResolveProps,
    filtered_orders: string[];
    order_book?: OrderBookProps;
    latest_trades_lists: {
        event_id:string,
        event_type: string,
        updated: number,
        trades: TradeProps[]
    }[];
    challenger?:PublicPlayerProps;
    draft_order?: OrderProps;
    selling_position?:PositionProps;
    player_filters: PlayerFilterProps[];
    suggested_markets_list: {
        updated: number,
        suggested_markets: EventOrderStatProps[]
    }
}

export const V1_LOAD_MARKETS = 'V1_LOAD_MARKETS';
export const V1_LOAD_ORDERS = 'V1_LOAD_ORDERS';
export const V1_LOAD_FILTERED_ORDERS = 'V1_LOAD_FILTERED_ORDERS';
export const V1_LOAD_ORDER_BOOK = 'V1_LOAD_ORDER_BOOK';
export const V1_LOAD_ORDER_STATS = 'V1_LOAD_ORDER_STATS';
export const V1_UPDATE_ORDER = 'V1_UPDATE_ORDER';
export const V1_LOAD_LATEST_TRADES = 'V1_LOAD_LATEST_TRADES';
export const V1_UPDATE_LATEST_TRADES = 'V1_UPDATE_LATEST_TRADES';
export const V1_UPDATE_LATEST_TRADE = 'V1_UPDATE_LATEST_TRADE';
export const V1_LOAD_TRADES = 'V1_LOAD_TRADES';
export const V1_UPDATE_TRADE = 'V1_UPDATE_TRADE';
export const V1_LOAD_DRAFT_ORDER = 'V1_LOAD_DRAFT_ORDER';
export const V1_LOAD_EXTERNAL_PRICE_COMPARISON = 'V1_LOAD_EXTERNAL_PRICE_COMPARISON';
export const V1_UPDATE_PLAYER_FILTERS = 'V1_UPDATE_PLAYER_FILTERS';
export const V1_LOAD_SUGGESTED_MARKETS = 'V1_LOAD_SUGGESTED_MARKETS';
export const V1_UPDATE_MARKET_RESOLVE = 'V1_UPDATE_MARKET_RESOLVE';
export const V1_UPDATE_CHALLENGER = 'V1_UPDATE_CHALLENGER';
export const V1_LOAD_BEST_AVAILABLE = 'V1_LOAD_BEST_AVAILABLE';


//Competition actions and reducers
export type CompetitionReducerProps = {
    competition_result_types: CompetitionResultTypeProps[];
    competition_types: CompetitionTypeProps[];
    draft_competition?: CreateCompetitionProps;
    
    stored_competitions: {
        updated:number,
        competitions:CompetitionProps[]
    };
    competition_seasons_list: {
        updated:number,
        competition_seasons:CompetitionSeasonProps[]
    },
    season_leaders_list:{
        updated:number,
        season_leaders:any[],
        competition_season_results:CompetitionSeasonResultProps[]
    },
    stored_records: {
        updated:number,
        records:CompetitionRecordProps[]
    }
    stored_results: {
        updated:number,
        results: CompetitionResultProps[]
    }
    player_brackets_list: {
        updated: number,
        player_brackets:PlayerBracketProps[];
    };
    player_bracket_picks_list: {
        updated: number,
        player_bracket_picks: PlayerBracketPickProps[]
    };
    competition_player_brackets_list: {
        updated:number,
        competition_player_brackets:CompetitionPlayerBracketProps[]
    }
    competition_players: CompetitionPlayerProps[];
    competition_matches: CompetitionMatchProps[];
    competition_match_markets:CompetitionMatchMarketProps[];
    my_picks:PlayerPickProps[];
    player_picks:PlayerPickProps[];
    invited_competition_players:CompetitionPlayerProps[];
    squares:SquareProps[], 
    player_squares:PlayerSquareProps[],
    squares_competitions_list: {
        updated:number,
        squares_competitions: SquaresCompetitionProps[]
    },
    squares_type:SquareProps | undefined, 
    squares_payout_type:SquaresPayoutProps|undefined, 
    squares_competition:SquaresCompetitionProps|undefined,
    bracket_competitions_list: {
        updated: number,
        bracket_competitions:BracketCompetitionProps[];
    }
    bracket_competition_scoring_rules_list: {
        updated:number,
        bracket_competition_scoring_rules:BracketCompetitionScoringRuleProps[]
    },
    square_results:SquareResultProps[],
    square_offers:SquareOfferProps[],
    wager_order:OrderProps | undefined,
    player_square_history:PlayerSquareProps[]
    squares_last_updated:any
}

export const V1_LOAD_COMPETITION_OPTIONS = 'V1_LOAD_COMPETITION_OPTIONS';
export const V1_LOAD_SQUARES_COMPETITIONS = 'V1_LOAD_SQUARES_COMPETITIONS';
export const V1_UPDATE_DRAFT_COMPETITION = 'V1_UPDATE_DRAFT_COMPETITION';
export const V1_UPDATE_COMPETITION_SEASONS = 'V1_UPDATE_COMPETITION_SEASONS';
export const V1_UPDATE_SEASON_LEADERS = 'V1_UPDATE_SEASON_LEADERS';
export const V1_UPDATE_COMPETITIONS = 'V1_UPDATE_COMPETITIONS';
export const V1_UPDATE_COMPETITION_PLAYERS = 'V1_UPDATE_COMPETITION_PLAYERS';
export const V1_UPDATE_COMPETITION_MATCHES = 'V1_UPDATE_COMPETITION_MATCHES';
export const V1_UPDATE_COMPETITION_MATCH_MARKETS = 'V1_UPDATE_COMPETITION_MATCH_MARKETS';
export const V1_UPDATE_COMPETITION_RESULTS = 'V1_UPDATE_COMPETITON_RESULTS';
export const V1_UPDATE_COMPETITION_RECORDS = 'V1_UPDATE_COMPETITION_RECORDS';
export const V1_UPDATE_MY_PLAYER_PICKS = 'V1_UPDATE_MY_PLAYER_PICKS';
export const V1_UPDATE_PLAYER_PICKS = 'V1_UPDATE_PLAYER_PICKS';
export const V1_UPDATE_INVITED_COMPETITION_PLAYERS = 'V1_UPDATE_INVITED_COMPETITION_PLAYERS';
export const V1_UPDATE_DRAFT_WAGER_ORDER = 'V1_UPDATE_DRAFT_WAGER_ORDER';
export const V1_UPDATE_SQUARES_COMPETITION = 'V1_UPDATE_SQUARES_COMPETITION';
export const V1_LOAD_SQUARE_DETAILS = 'V1_LOAD_SQUARE_DETAILS';
export const V1_UPDATE_SQUARE_DETAILS = 'V1_UPDATE_SQUARE_DETAILS';
export const V1_LOAD_PLAYER_BRACKETS = 'V1_LOAD_PLAYER_BRACKETS';
export const V1_UPDATE_PLAYER_BRACKET_PICKS = 'V1_UPDATE_PLAYER_BRACKET_PICKS';
export const V1_UPDATE_BRACKET_COMPETITIONS = 'V1_UPDATE_BRACKET_COMPETITIONS';
export const V1_UPDATE_COMPETITION_PLAYER_BRACKETS = 'V1_UPDATE_COMPETITION_PLAYER_BRACKETS';
export const V1_UPDATE_BRACKET_COMPETITION_SCORING_RULES = 'V1_UPDATE_BRACKET_COMPETITION_SCORING_RULES';


//Analytics Reducer props
export type AnalyticsReducerProps = {
    leaders: LeaderProps[],
    filter: FilterProps,
    metrics: {
        overall: MetricsProps[],
        markets: MetricsProps[],
        competitions: MetricsProps[],
        squares: MetricsProps[],
    }
    order_ids:string[],
    stored_categories: CategoryProps[],
    stored_timeranges: TimerangeProps[]
}

export const V1_LOAD_LEADERS = 'V1_LOAD_LEADERS';
export const V1_UPDATE_LEADER = 'V1_UPDATE_LEADER';
export const V1_LOAD_CATEGORIES = 'V1_LOAD_CATEGORIES';
export const V1_LOAD_TIMERANGES = 'V1_LOAD_TIMERANGES';
export const V1_LOAD_OVERALL_METRICS = 'V1_LOAD_OVERALL_METRICS';
export const V1_UPDATE_METRIC_FILTER = 'V1_UPDATE_METRIC_FILTER';

export interface HelmetProps {
    title:string,
    description:string,
    image:string,
    updated:number,
    additional_tags: { property:string, content:string }[]
}
//Util Reducer Props
export type UtilReducerProps = {
    app_size: { width: number, height:number },
    audio_height: number,
    socket_connected: boolean,
    default_lines_visible: boolean,
    header_height: number,
    bottom_tab_height:number,
    deferred_prompt?:any,
    inital_url?: string,
    analytics_session_id?:string,
    distinct_id?:string,
    viewed_prompts: string[],
    helmet_state: HelmetProps,
    fulfill_alert_closed?:boolean,
    wallet_visible?:boolean,
    catalog_visible?:boolean,
    location_visible:boolean,
    location:{
        permission:{
            checked:boolean,
            permission?:string
        },
        location: {
            loading: boolean;
            error?: string;
            coordinates: any;
            player_id?: string;
            address_results: any[];
            player_address?: any;
            region?: string;
            location?: any;
        }
    },
    item_order?:any,
    current_route?: { route?:string, stack_key:string, key:string, params?:any },
    last_route?:{ stack_key:string, key:string, params?:any },
    help_soft_prompts:{
        id:string,
        client_native_id?:string,
        prompt:string,
        body?:string
        actions: any[]
    }[] //Client native Id
    help?: {
        initial_id:string,
        view_type: string,
        help_prompt?:HelpPromptType,
        help_tour?: HelpTourType,
        tour_prompts: HelpPromptType[],
        active_tour_prompt: number ,
        selected_action_type?:string
    },
    help_action_select?:{
        client_native_id:string,
        selected_action_type: string
    },
    app_alerts_list: {
        updated:number,
        app_alerts: AppAlertProps[]
    },
    cache_requests:{ request_url:string, response_data:any, timestamp:any }[],
    show_authenticate: {
        visible: boolean,
        hide_close?:boolean
    },
    show_profile:{
        visible: boolean,
        show_welcome?:boolean,
        profile_attribute?:string
    },
    analytic_tokens: {
        set:boolean,
        distinct_id:string,
        session_id:string
    }
    auth_tokens: {
        player_id?:string,
        access_token?:string,
        refresh_token?:string,
        expire_datetime?:any
    },
    show_wallet: {
        visible:boolean,
        account_id?:string
    },
    show_checkout: {
        visible:boolean,
        draft_order?:ItemOrderProps
    }
}

export const V1_AUTHENTICATE_APP = 'V1_AUTHENTICATE_APP';
export const V1_OBSERVE_APP = 'V1_OBSERVE_APP';
export const V1_SHOW_WALLET = 'V1_SHOW_WALLET';
export const V1_SHOW_CHECKOUT = 'V1_SHOW_CHECKOUT';
export const V1_UPDATE_APP_SIZE = 'V1_UPDATE_APP_SIZE';
export const V1_FULFILL_ALERT_CLOSED = 'V1_FULFILL_ALERT_CLOSED';
export const V1_LOAD_APP_ALERTS = 'V1_LOAD_APP_ALERTS';
export const V1_UPDATE_DEFAULT_LINES_VISIBLE = 'V1_UPDATE_DEFAULT_LINES_VISIBLE';
export const V1_VIEW_PROMPT = 'V1_VIEW_PROMPT';
export const V1_VIEW_ALL_PROMPTS = 'V1_VIEW_ALL_PROMPTS';
export const V1_UPDATE_BOTTOM_TAB_HEIGHT = 'V1_UPDATE_BOTTOM_TAB_HEIGHT';
export const V1_UPDATE_APP_ALERT = 'V1_UPDATE_APP_ALERT';
export const V1_UPDATE_CACHE_REQUEST = 'V1_UPDATE_CACHE_REQUEST';
export const V1_UPDATE_AUDIO_HEIGHT = 'V1_UPDATE_AUDIO_HEIGHT';
export const V1_UPDATE_HEADER_HEIGHT = 'V1_UPDATE_HEADER_HEIGHT';
export const V1_UPDATE_SOCKET_CONNECTED = 'V1_UPDATE_SOCKET_CONNECTED';
export const V1_LOAD_HELP = 'V1_LOAD_HELP';
export const V1_CLEAR_HELP = 'V1_CLEAR_HELP';
export const V1_LOAD_HELP_SOFT_PROMPTS = 'V1_LOAD_HELP_SOFT_PROMPTS';
export const V1_SELECT_ACTION = 'V1_SELECT_ACTION';
export const V1_UPDATE_LAST_ROUTE = 'V1_UPDATE_LAST_ROUTE';
export const V1_UPDATE_INITIAL_URL = 'V1_UPDATE_INITIAL_URL';
export const V1_UPDATE_INSTALL_PROMPT = 'V1_UPDATE_INSTALL_PROMPT';
export const V1_UPDATE_WALLET_VISIBILE = 'V1_UPDATE_WALLET_VISIBLE';
export const V1_UPDATE_ITEM_ORDER = 'V1_UPDATE_ITEM_ORDER';
export const V1_UPDATE_CATALOG_VISIBLE = 'V1_UPDATE_CATALOG_VISIBLE';
export const V1_UPDATE_HELMET = 'V1_UPDATE_HELMET';
export const V1_UPDATE_SESSION_ID = 'V1_UPDATE_SESSION_ID';
export const V1_UPDATE_DISTINCT_ID = 'V1_UPDATE_DISTINCT_ID';
export const V1_SHOW_AUTHENTICATE = 'V1_SHOW_AUTHENTICATE';
export const V1_SHOW_PROFILE = 'V1_SHOW_PROFILE';
export const V1_LOAD_LOCATION = 'V1_LOAD_LOCATION';
export const V1_SHOW_LOCATION = 'V1_SHOW_LOCATION'


export type PollReducerProps = {
    poll_campaigns_list: {
        updated: number,
        poll_campaigns:PollCampaignProps[]
    },
    poll_campaign_goals_list: {
        updated:number,
        poll_campaign_goals:PollCampaignGoalProps[]
    },
    poll_campaign_leaders_list: {
        updated:number,
        poll_campaign_leaders:PollCampaignLeaderProps[]
    },
    polls_list: {
        updated:number,
        polls: PollProps[]
    },
    poll_options_list: {
        updated:number,
        poll_options: PollOptionProps[]
    }
    poll_summaries_list: {
        updated:number,
        poll_summaries: PollSummaryProps[]
    }
}


export const V1_UPDATE_POLL_CAMPAIGNS = 'V1_UPDATE_POLL_CAMPAIGNS';
export const V1_UPDATE_POLL_CAMPAIGN_GOALS = 'V1_UPDATE_POLL_CAMPAIGN_GOALS';
export const V1_UPDATE_POLLS = 'V1_UPDATE_POLLS';
export const V1_UPDATE_POLL_CAMPAIGN_LEADERS = 'V1_UPDATE_POLL_CAMPAIGN_LEADERS';
export const V1_UPDATE_POLL_OPTIONS = 'V1_UPDATE_POLL_OPTIONS';
export const V1_UPDATE_POLL_SUMMARIES = 'V1_UPDATE_POLL_SUMMARIES';