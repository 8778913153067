import React, { useEffect } from 'react';
import { Components, LocationTracker, usePlayerLocation } from 'be-components';
import { ActivityIndicator, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../constants/Colorsv2';
import { V1_LOAD_LOCATION, V1_SHOW_LOCATION } from '../store/actionsv1/types';
import AsyncStorage from '@react-native-async-storage/async-storage';

const LocationHolder = ({}) => {
    const dispatch = useDispatch();
    const player = useSelector((state:any) => state.player.player, (left, right) => left?.player_id == right?.player_id);
    const analytic_tokens = useSelector((state:any) => state.util.analytic_tokens, (left, right) => left == right);
    const location_visible = useSelector((state:any) => state.util.location_visible, (left, right) => left == right);
    const { permission, location, getLocation, checkPermissions } = usePlayerLocation({ player_id: player?.player_id, distinct_id: analytic_tokens.distinct_id });
    useEffect(() => {
       handlePermissionChange();
    },[player?.player_id])

    useEffect(() => {
        dispatch({ type:V1_LOAD_LOCATION, permission, location })
        if(location_visible && location.location){ 
            dispatch({ type:V1_SHOW_LOCATION, visible:false })
            storeGranted();
        }
    },[location])

    const storeGranted = async() => {
        await AsyncStorage.setItem('permission_granted', 'true')
    }
    const removeGranted = async() => {
        await AsyncStorage.removeItem('permission_granted')
    }

    const handlePermissionChange = async() => {
        dispatch({ type:V1_LOAD_LOCATION, permission, location })
        //if we haven't checked the users permissions yet - lets do so meow
        if(!permission.checked){ return checkPermissions() }
        switch(permission.permission){
            case 'prompt':
                //If it is prompt - just save for future use.  We will ask later at a more opportune time
                if(!player?.player_id){ return }
                const permission_granted = await AsyncStorage.getItem('permission_granted')
                if(permission_granted && !location.loading){ return getLocation(); }
                return dispatch({ type:V1_SHOW_LOCATION, visible:true })
            case 'denied':
                removeGranted();
                if(!player?.player_id){ return }
                return dispatch({ type:V1_SHOW_LOCATION, visible:true })
                //Just save for future use.  We will show that there is a failure to get it
            case 'granted':
                if(!location.loading && (!location.region || !location.location)){ 
                    return getLocation(); 
                }
                //If it is granted - lets snag the location!
                
            default: return //This shouldnt hit
        }
    }
    if(location.loading){
        return (
            <ActivityIndicator
                style={{ position:'absolute', top:10, left:10 }}
                size={'large'}
                color={Colors.utility.warning}
            />
        )
    }
    if(!location_visible){ return <></> }
    return (
        <View style={{ position:'absolute', left:0, right:0, bottom:0, top:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
            <Components.Spring
                slide='vertical'
                from={400}
                to={0}
            >
                <View style={{ backgroundColor:Colors.shades.white, borderTopRightRadius:22, borderTopLeftRadius:22 }}>
                    <LocationTracker
                        onClose={() => dispatch({ type: V1_SHOW_LOCATION, visible:false })}
                        onGetLocation={() => getLocation({ no_cache:true })}
                        permission={permission}
                        location={location}
                    />
                </View>
            </Components.Spring>
        </View>
    )
}

export default LocationHolder 