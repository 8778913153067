
import "@storedotapp/pwa-install-dialog";
//Import React Components
import { HelmetProvider } from 'react-helmet-async';
import React, { Suspense, useEffect, useState } from 'react';
import { View, ActivityIndicator, Image, Dimensions, ImageBackground } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './utils/global/ErrorFallback';
import { enableScreens } from 'react-native-screens';
enableScreens();

import { APIOverrides, AdProvider } from 'be-components';

//Import Expo Related Libraries
import { Asset } from 'expo-asset';
import { LinearGradient } from 'expo-linear-gradient';
import * as Font from 'expo-font';

//Import Redux functions
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import store from './store';
let persistor = persistStore(store) 

//Import Charting Library to override for shaping
import Chart from 'chart.js';
import { chartModifier } from './utils/charts/chartUtils';
chartModifier(Chart)


import { listenForNotifications } from './utils/notifications/webNotification';
import BELoader from './globalComponents/BELoader';
import { BELoaderHolder } from './globalComponents/BELoaderHolder';

//Import Internal Components/
const MainTabNavigator = React.lazy(() => import('./navigation/MainTabNavigator'));

//Import themes and colors
import Colors from './constants/Colorsv2';

//Service worker
import * as serviceWorkerRegistration from "./web/serviceWorkerRegistration";
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

/*
All actions needed to load the application for the user are called out here
*/
//Auth Actions

//Segment Start Reporting 
import { startSegment } from './utils/analytics/segment';
import HelpManager from './help/HelpManager';
import { setAPIKey } from './screensV1/Auth/helpers/security';
import OrderModal from './screensV1/Event/components/OrderModal';
import OrderAlertModal from './Componentsv1/OrderAlertModal';
import AlertBanner from './Componentsv1/AlertBanner';
import ActionModal from './Componentsv1/ActionModal';
import { util_updateAppSize } from './store/actionsv1/utils';
import { market_getMarkets } from './store/actionsv1/markets';
import PlaybackBar from './screensV1/Social/components/PlaybackBar';
import { auth_authenticateApp, auth_getAppAlerts, auth_observeApp } from './store/actionsv1/auth';
import AppNofityBanner from './Componentsv1/AppNotifyBanner';
import AppAlertBanner from './Componentsv1/AppAlertBanner';
import FullSizeImage from './Componentsv1/FullSizeImage';
import { V1_UPDATE_INSTALL_PROMPT } from './store/actionsv1/types';
import MarketResolveForm from './screensV1/Event/components/MarketResolveForm';
import OrderReviewCard from './Componentsv1/OrderReviewCard';
import { player_getPremiumItems } from "./store/actionsv1/player";
import DefaultLinesToggle from "./screensV1/Event/components/DefaultLinesToggle";
import ExternalPriceModal from "./screensV1/Event/components/ExternalPriceModal";
import TermsModal from "./Componentsv1/TermsModal";
import AuthenticationModal from "./Componentsv1/AuthenticationModal";
import ObserverHolder from "./Componentsv1/ObserverHolder";
import ProfileModal from "./Componentsv1/ProfileModal";
import { socket_authenticate } from "./store/actionsv1/socket";
import WalletHolder from "./Componentsv1/WalletHolder";
import CheckoutHolder from "./Componentsv1/CheckoutHolder";
import LocationHolder from "./Componentsv1/LocationHolder";



listenForNotifications(store)
//startSegment()
setAPIKey()

//Load App
function App({}) {
  const [ appSize, setAppSize ] = useState({ height: 0, width: 0 })
  const [ loaded, setLoaded ] = useState(false);
  const [ loadingMessage, setLoadingMessage ] = useState('Loading')


  useEffect(() => {
    //instantly grab the install prompt if fires
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      store.dispatch({ type:V1_UPDATE_INSTALL_PROMPT, deferred_prompt: e })
    })
    loadResourcesAsync()
  },[])


  useEffect(() => {
    store.dispatch(util_updateAppSize(appSize))
  },[appSize])

  const loadResourcesAsync = async() => {
    try {
      //Get the players location
      store.dispatch(auth_getAppAlerts())
      const resp = await APIOverrides.start({
        env: 'PRODUCTION',
        no_cache:false
      })
      hanldeAnalyze(resp)
      if(resp.authenticated){ handleAuthenticate(resp); }
      
      

      //handle notifications
      //Get the player's location
      loadFontAndImages()

      store.dispatch(market_getMarkets())
      store.dispatch(player_getPremiumItems())
      //Check if app is out of date
      setLoaded(true)
    } catch(e){
      console.log(e)
      setLoaded(true) 
    }   
}

const hanldeAnalyze = (resp:any) => {
  store.dispatch(auth_observeApp({
    distinct_id:resp.distinct_id,
    session_id: resp.session_id
  }))
}

const handleAuthenticate = async(resp:any) => {
  await APIOverrides.authenticateApp(resp, { cache: true })
  store.dispatch(auth_authenticateApp({
    authenticated:resp.authenticated, 
    access_token:resp.access_token,
    refresh_token: resp.refresh_token,
    expire_datetime: resp.expire_datetime,
    player_id: resp.player_id
  }))
  let distinct_id = store.getState().util.analytic_tokens.distinct_id
  socket_authenticate(resp.access_token, distinct_id)
}


const loadFontAndImages = async() => {
  try {
    await Promise.all([
      Asset.loadAsync([
        require('./assets/images/be_logo.png'),
        require('./assets/images/be-logo-transparent.png'),
        require('./assets/images/splash.png')
      ]),
      
      Font.loadAsync({
        'barlow-bold' : require('./assets/fonts/Barlow-Bold.ttf'),
        'barlow-semibold': require('./assets/fonts/Barlow-SemiBold.ttf'),
        'barlow-regular': require('./assets/fonts/Barlow-Regular.ttf'),
        'barlow-light': require('./assets/fonts/Barlow-Light.ttf')
      })
    ]);
    return
  } catch (e) {
    return e
  }
}

const helmetContext = {};

if(!loaded){
  return <BeforeReady loadingMessage="loading" appHeight={appSize.height} appWidth={appSize.width}/>
}


return (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
  <HelmetProvider context={helmetContext}>

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SafeAreaProvider>
          <AdProvider
            distinct_id={store.getState().util.analytic_tokens.distinct_id}
            session_id={store.getState().util.analytic_tokens.session_id}
          >
        <LinearGradient style={{flex:1, flexDirection:'row', justifyContent:'center', backgroundColor:Colors.shades.black}}
        colors={[Colors.brand.midnight, Colors.brand.midnight]}>
        <View style={{flex:1, maxWidth:550}} onLayout={(ev) => {
          const { height, width } = ev.nativeEvent.layout
            setAppSize({ height, width })
        }}> 
          <Suspense fallback={<BeforeReady loadingMessage={loadingMessage} allowVideo={false} appWidth={appSize.width} appHeight={appSize.height} />}>
            <MainTabNavigator appHeight={appSize.height} />
          </Suspense>
          <BELoader
          ref={(ref) => BELoaderHolder.setLoader(ref)} />
          <PlaybackBar />
          <OrderModal width={appSize.width}/>
          <OrderAlertModal width={appSize.width} />
          <ActionModal width={appSize.width} />
          <ExternalPriceModal width={appSize.width}/>
          <MarketResolveForm />
          <AlertBanner />

          <AppNofityBanner />
          <OrderReviewCard 
            width={appSize.width}
            height={appSize.height}
          />
        </View>
          <AppAlertBanner />
          <FullSizeImage />
          <HelpManager  />
          
          <DefaultLinesToggle />
          <TermsModal />
          <LocationHolder />
          <AuthenticationModal
            onAuthenticated={(resp) => handleAuthenticate({ ...resp, authenticated:true })}
          />
          <ProfileModal />
        </LinearGradient>
        <WalletHolder />
        <CheckoutHolder />
        </AdProvider>
      </SafeAreaProvider>
      <ObserverHolder />
  </PersistGate>
</Provider>
</HelmetProvider>
</ErrorBoundary>
)
}


export const BeforeReady = (({ appHeight, appWidth}: {loadingMessage:string, appHeight:number, appWidth:number}) => {
  const [ loaded, setLoaded ] = useState(false)
    
  let imageWidth =  189
  let imageHeight = 145


  return (
      <ImageBackground
        source={require('./assets/images/splash.png')} 
        onLoad={() => setLoaded(true)}
        style={{ height:appHeight, width:appWidth }}
        resizeMethod="scale"
      >
          <View style={{ position:'absolute', top:0, bottom:0, left:0, right:0, backgroundColor:Colors.shades.black_faded }}>
                <Image
                    source={require('./assets/images/social_sports.webp')}
                    style={{ height:appHeight, width:appWidth, opacity: 0.8 }}
                    resizeMode='cover'
                />
            </View>
            <Image
                source={require('./assets/images/be_lightning.webp')} 
                style={{ width:appWidth, height:appWidth*0.2 }}
                resizeMode='cover'
                />
            {loaded ?
            <View style={{justifyContent:'center', alignItems:'center', padding:30, marginTop:0}}>
                <Image source={require('./assets/images/be_descriptor.webp')} style={{ width:220, height:52 }} resizeMode='contain' />
            </View>
            :<></>}
            {loaded ?
            <View style={{ flex:1, padding:20 }}>
              <ActivityIndicator size='large' color={Colors.shades.white} />
            </View>  
            :<></>} 
      </ImageBackground>
  )
})


export default App;