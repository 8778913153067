import React, { useEffect, useState } from 'react';
import { Image, View } from "react-native"
import { Icon } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { util_updateAppAlert } from '../store/actionsv1/utils';
import { AppAlertProps } from '../store/types/auth_svc';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { navigate } from '../navigation/RootNavigation';
import { ImpressionProps } from '../store/types/soc_svc';
import { social_logImpression } from '../store/actionsv1/social';
import moment from 'moment';
import { V1_HIDE_ALERT } from '../store/actionsv1/types';
import { player_getHelpPromptById } from '../store/actionsv1/player';



const AppAlertBanner = ({ }:any) => {

    const [ app_alert, setAppAlert ] = useState<AppAlertProps | undefined>(undefined)
    const dispatch = useDispatch();

    let app_alerts_list:{ updated:number, app_alerts:AppAlertProps[] } = useSelector((state:any) => state.util.app_alerts_list, (left, right) => left?.updated == right?.updated)
    const app_size:{width:number} = useSelector((state:any) => state.util.app_size, (left, right) => left.width == right.width);
    const player = useSelector((state:any) => state.player.player, (left, right) => left?.player_id == right?.player_id)
    const app_alerts = app_alerts_list.app_alerts.filter(a => a.status == 'active')
    const hidden_alerts = useSelector((state:any) => state.auth.hidden_alerts, (left, right) => left?.length == right?.length);

    useEffect(() => {
        if(app_alerts.length == 0){ return setAppAlert(undefined) }

        let going_to_show_app_alert = app_alerts[0]
        if(hidden_alerts){
            let is_hidden = hidden_alerts.find(a => a == going_to_show_app_alert.app_alert_id)
            if(is_hidden){
                console.log('Already saw this one!')
                dispatch(util_updateAppAlert({ ...going_to_show_app_alert, status:'inactive' }))
                return
            }
        }
        if(!app_alert){ return setAppAlert(app_alerts[0]) }
        let existing_alert = app_alerts.find(a => a.app_alert_id == app_alert.app_alert_id)
        if(!existing_alert){ return setAppAlert(app_alerts[0]) }
    },[app_alerts.length])


    const handlePress = async() => {
        if(!app_alert){ return }
        dispatch(util_updateAppAlert({ ...app_alert, status:'inactive' }))
        dispatch({ type: V1_HIDE_ALERT, app_alert_id: app_alert.app_alert_id })
        try {
            if(app_alert.landing_link_type == 'external'){ window.open(app_alert.external_link, '_blank') }

            if(app_alert.landing_link_type == 'internal' && app_alert.page_stack && app_alert.screen){
                return navigate(app_alert.page_stack, { screen: app_alert.screen, initial:false, params: app_alert.page_params })
            }
            if(app_alert.landing_link_type == 'help' && app_alert.external_link){
                return dispatch(player_getHelpPromptById(app_alert.external_link))
            }
        } catch (e) {
            return
        }

    }
    
    const insets = useSafeAreaInsets();


    if(!app_alert || !player){ return <></> }
    return (
        <View style={{ position:'absolute', top:insets.top, right:0, left:0, padding:10, justifyContent:'center', alignItems:'center' }}>
            <TouchableOpacity style={{ backgroundColor:app_alert.background_color, width:app_size.width - 20, flexDirection:'row', alignItems:'center', padding:10, borderRadius:8 }} onPress={() => handlePress()}>
                <View style={{ flex:1/6, justifyContent:'center', alignItems:'flex-start' }}>
                    {app_alert.image_url ?
                    <Image source={app_alert.image_url} style={{ height:40, width:40 }} resizeMode='cover'/>
                    :
                    <Icon name='alert-circle' type='feather' color={app_alert.text_color} size={30} />
                    }
                </View>
                <View style={{ flex:4/6 }}>
                    <Text color={app_alert.text_color} size={14} weight='bold'>{app_alert.title}</Text>
                    <Text style={{ marginTop:4 }} color={app_alert.text_color} size={14} weight='regular'>{app_alert.body}</Text>
                </View>
                <TouchableOpacity style={{ flex:1/6, justifyContent:'center', alignItems:'flex-end' }} onPress={() => {
                    //if(app_alert.landing_link_type == 'help' && app_alert.external_link){
                    //    dispatch(player_getHelpPromptById(app_alert.external_link))
                    //}
                    dispatch(util_updateAppAlert({ ...app_alert, status:'inactive' }))
                    dispatch({ type: V1_HIDE_ALERT, app_alert_id: app_alert.app_alert_id })
                }}>
                    <Icon name='x-circle' type='feather' color={app_alert.text_color} size={30} />
                </TouchableOpacity>
            </TouchableOpacity>
        </View>
    )

}

export default AppAlertBanner