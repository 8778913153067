import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, ScrollView, TextInput, View } from 'react-native';
import { connect, useSelector } from "react-redux"
import Colors from "../../../constants/Colorsv2"
import Modal from 'modal-enhanced-react-native-web';
import { MarketProps, OrderProps, PositionProps, TradeProps } from "../../../store/types/mk_svc";
import { market_createOrder, market_gradeOrder, market_setDraftOrder } from '../../../store/actionsv1/markets';
import { Text, TouchableOpacity } from '../../../globalComponents/NativeOverrides';
import Styles from '../../../constants/Styles';
import { order_calcAmericanOddsFromProbability, order_calcPotentialWinnings, order_calcProbabilityFromOdds, order_calcSaleValue, order_getOddsFromProbability, order_getOrderTitleForMatch, order_getOrderTitleForTeamEvent, order_getOrderTitleForTournament, order_isOrderValid } from '../helpers/orders';
import { AthleteProps, EventProps, MatchProps, TeamProps, TournamentProps } from '../../../store/types/sr_svc';
import { PlayerBalanceProps, PlayerSettingProps, SettingProps } from '../../../store/types/auth_svc';
import moment from 'moment';
import ExpirationDatePicker from './ExpirationDatePicker';
import PlayerSelector from '../../Social/components/PlayerSelector';
import { market_getOddsLabel } from '../helpers/markets';
import { Icon } from 'react-native-elements';
import { navigate } from '../../../navigation/RootNavigation';
import EventInfoCard from './EventInfoCard';
import { event_isLive } from '../helpers/events';
import { CompetitionPlayerProps } from '../../../store/types/tp_svc';
import { comps_setDraftWagerOrder } from '../../../store/actionsv1/comps';
import { event_getAthleteByAthleteId } from '../../../store/actionsv1/events';
import OrderGradeBar from '../../../Componentsv1/OrderGradeBar';
import OrderSuggestionCard from './OrderSuggestionCard';
import { Battle, BetSlip } from '../../../Componentsv1/Icons';
import { V1_SHOW_AUTHENTICATE, V1_SHOW_PROFILE, V1_SHOW_WALLET } from '../../../store/actionsv1/types';


type OrderModalProps = {    
    isAuthenticated: boolean;
    player_balance: PlayerBalanceProps
    order?: OrderProps;
    app_size: { width:number, height:number },
    selling_position?:PositionProps
    event?: EventProps;
    tournament?:TournamentProps;
    match?:MatchProps;
    athlete?:AthleteProps;
    event_getAthleteByAthleteId: (athlete_id:string) => void;
    team?:TeamProps;
    markets: MarketProps[];
    market_setDraftOrder:(order?:OrderProps, position?:PositionProps) => void;
    market_createOrder:(order:OrderProps) => void;
}
const DEFAULT_ORDER_STATE = {
    loading: false,
    saved_for_later:false,
    active_tab: 'market',
    reviewed: false,
    collar_pct: 0,
    probability_label: '0',
    suggestion_hidden:false,
    odds_label: '0',
    price_preference: 'probability'
}
const OrderModal = ({ isAuthenticated, player, exotic, player_balance, challenger, showAuthenticate, showWallet, showVouch, event_getAthleteByAthleteId, latest_trades_list, comps_setDraftWagerOrder, app_size, market_type, player_settings, settings, athlete, team, order, selling_position, event, tournament, match, markets, market_setDraftOrder, market_createOrder }:OrderModalProps) => {
    const [ order_state, setOrderState ] = useState(DEFAULT_ORDER_STATE)
    const [ expire_time, setExpireTime ] = useState<any>()
    const [ use_promo_balance, setUsePromoBalance ] = useState(false);
    const [ order_suggestion_height, setOrderSuggestionHeight ] = useState(0)
    const [ input_location, setInputLocation ] = useState({ x:0, y:0, width: 0, height:0 })
    const { loading, active_tab, reviewed, collar_pct, probability_label, odds_label, price_preference, suggestion_hidden } = order_state
    const bottom_tab_height = useSelector((state:any) => state.util.bottom_tab_height, (left, right) => left == right);
    
    
    const location = useSelector((state:any) => state.util.location.location, (left, right) => left == right);
    let commission_pct = 0
    if(location.location?.commission_pct){
        commission_pct = location.location.commission_pct
    }
    const competition_player:CompetitionPlayerProps | undefined = useSelector((state:any) => state.comp.competition_players.find((cp:CompetitionPlayerProps) => cp.competition_id == order?.competition_id && cp.player_id === order?.player_id), (left, right) => left?.competition_player_id == right?.competition_player_id)
    let latest_trade: TradeProps | undefined = undefined
    if(latest_trades_list){
        latest_trade = latest_trades_list.find(t => t.market_id == order?.market_id && t.market_type == market_type && t.side == order?.side && t.side_id == order?.side_id)
    }

    //Get default unit size if available
    let unit_size_setting = settings.find((s:SettingProps) => s.setting === 'unit_size');
    let unit_size = player_settings.find((ps:PlayerSettingProps) => ps.setting_id == unit_size_setting?.setting_id)?.selected_option

    if(!unit_size){ unit_size = 10 }

    let quick_amounts = [
        { label: '1x', amount:parseFloat(unit_size) }, { label: '2x', amount: parseFloat(unit_size) * 2 }, { label: '3x', amount: parseFloat(unit_size)*3 }
    ]
    if(order?.draft_liquidity){
        quick_amounts.push({ label: 'all', amount: Math.round(parseFloat(order.draft_liquidity)*100)/100 })
    }

    //Get the market
    const market = markets.find(m => m.market_id == order?.market_id)
    let order_title = order?.title??''
    //Get the required settings
    let default_collar_pct:number = active_tab === 'market' ? 0.02 : 0
    let collar_setting = settings.find((s:SettingProps) => s.setting === 'collar_pct')
    let default_price_preference:SettingProps = settings.find((s:SettingProps) => s.setting === 'price_preference')
    let player_pref:PlayerSettingProps | undefined
    if(default_price_preference){
        player_pref = player_settings.find((ps:PlayerSettingProps) => ps.setting_id == default_price_preference.setting_id)
    }
    if(active_tab === 'market' && collar_setting){ 
        let ps = player_settings.find((ps:PlayerSettingProps) => ps.setting_id == collar_setting.setting_id) 
        if(ps){ default_collar_pct = parseFloat(ps.selected_option) }
    }

    
    useEffect(() => {
        if(order_state.saved_for_later){
            setExpireTime(undefined)
            market_setDraftOrder()
            return setOrderState(DEFAULT_ORDER_STATE) 
        }
        if(!order){ 
            setUsePromoBalance(false);
            return setOrderState({ ...DEFAULT_ORDER_STATE, price_preference: player_pref?.selected_option??DEFAULT_ORDER_STATE.price_preference }) 
        }
        if(order && odds_label === '0'){ 
            setOrderState({ ...order_state, active_tab: challenger ? 'h2h' : order.order_type, odds_label: market_getOddsLabel(order.odds), probability_label: (order_calcProbabilityFromOdds(order.odds)*100).toFixed(2) }) }
        if(order.side_type === 'athlete' && !athlete){ event_getAthleteByAthleteId(order.side_id) }
        if(order_state.reviewed){ return setOrderState({ ...order_state, reviewed: false }) }
    },[order])

    useEffect(() => {
        if(!player_pref){ return }
        if(!order){ return }
        setOrderState({ ...order_state, price_preference: player_pref.selected_option,  odds_label: market_getOddsLabel(order.odds), probability_label: (order_calcProbabilityFromOdds(order.odds)*100).toFixed(2) })
    },[player_pref])

    useEffect(() => {
        if(order_state.reviewed){ return setOrderState({ ...order_state, reviewed: false }) }
    },[probability_label, odds_label])

    
    useEffect(() => {
        if(!order){ return }
        let odds = parseFloat(odds_label)
        if(isNaN(odds)){ return }
        market_setDraftOrder({ ...order, odds, h2h_id:challenger?.player_id, h2h_user:challenger, probability: order_calcProbabilityFromOdds(odds) }, selling_position)
    },[odds_label])
    

    useEffect(() => {
        if(!order){ return }
        let probability = parseFloat(probability_label)
        if(isNaN(probability)){ return } else { probability = probability / 100 }
        market_setDraftOrder({ ...order, probability, h2h_id:challenger?.player_id, h2h_user:challenger, odds: order_calcAmericanOddsFromProbability(probability) }, selling_position)
    },[probability_label])
       
    useEffect(() => {
        if(!order){ return }
        market_setDraftOrder({ ...order, market_type }, selling_position)
    },[market_type])

    useEffect(() => {
        if(active_tab === 'market' && latest_trade){
            setOrderState({ ...order_state, probability_label: (latest_trade.probability*100).toFixed(2), odds_label: latest_trade.odds.toFixed(2) })
            return
        }
    },[active_tab])
    

    const handlePriceChange = (new_order:OrderProps) => {
        market_setDraftOrder(new_order, selling_position)
        setOrderSuggestionHeight(0)
        setOrderState({
            ...order_state,
            probability_label: (new_order.probability*100).toFixed(2),
            odds_label: market_getOddsLabel(new_order.odds),
            active_tab: 'limit'
        })
    }

    const getOrderTitle = (local_order?:OrderProps) => {
        if(!local_order || !market){ return '' }
        if(local_order.event_type === 'tournament' && tournament){ order_title = order_getOrderTitleForTournament(local_order, market, tournament, athlete, team) }
        if(local_order.event_type === 'team' && event){ order_title = order_getOrderTitleForTeamEvent(local_order, market, event, athlete, exotic) }
        if(local_order.event_type === 'match' && match){ order_title = order_getOrderTitleForMatch(local_order, market, match, athlete, team) }
        return order_title
    }

    const handleVerifyNow = () => {
        showVouch();
        return setOrderState({ ...order_state, saved_for_later:true })
    }

    const handleSignIn = () => {
        //market_setDraftOrder()
        setOrderState({ ...order_state, saved_for_later: true })
        showAuthenticate()
        //navigate('AuthStack', { screen:'Login' })
    }

    const handleUpdateOrder = (attribute:string, value:string) => {
        let new_order = { ...order, [attribute]: value }
        new_order.title = getOrderTitle(new_order)
        market_setDraftOrder(new_order, selling_position)
    }


    const checkBalance = () => {
        if(!order){ return false }
        if(order.competition_id && !competition_player){ return false }
        if(order.competition_id && order.open_amt > competition_player?.wager_based_balance){ return false }
        if(order.competition_id && order.open_amt <= competition_player?.wager_based_balance){ return true }
        if(use_promo_balance && order.open_amt > player_balance.promo_balance){ return false }
        if(!use_promo_balance && market_type === 'FOR_MONEY' && order.buy_sell_ind === 'buy' && order.open_amt > player_balance.balance){ return false }
        if(market_type === 'FREE' && order.buy_sell_ind === 'buy' && order.open_amt > player_balance.free_market_balance){ return false }
        if(order.buy_sell_ind === 'sell' && order.open_amt > selling_position.stake){ return false }
        return true
    }
    const submitOrder = async() => {
        if(!order || loading){ return }
        if(!checkBalance()){ 
            showWallet();
            return setOrderState({ ...order_state, saved_for_later:true })
        }
        if(order.h2h_id == player?.player_id){ return alert('Cannot challenge yourself to a h2h challenge') }
        //Get the league_id
        let league_id:string | undefined
        if(order.event_type == 'team' && event){ league_id = event.league_id }
        if(order.event_type == 'tournament' && tournament){ league_id = tournament.league_id }
        let live = false
        if(order.event_type === 'team' && event){ live = event_isLive(event) }
        let probability = parseFloat(probability_label)/100
        let odds = order_calcAmericanOddsFromProbability(probability)
        let new_order:OrderProps = { ...order, league_id, odds, probability }
        if(active_tab === 'h2h' && !new_order.h2h_id){ 
            if(!challenger){ return alert('username must be selected for h2h order') }
            new_order.h2h_id = challenger.player_id
            new_order.h2h_user = challenger
        }

        if(active_tab != 'h2h' && challenger){ return alert('Please remove active h2h challenger by pressing the red X on the active challenger banner') }

        let errors = order_isOrderValid(new_order, market)
        if(errors.length > 0){ return alert(errors[0]) }
        setOrderState({ ...order_state, loading:true })
        if(!reviewed){
            if(!order.competition_id){
                let graded_order = await market_gradeOrder(new_order)
                if(graded_order.grade || graded_order.bet_scope_grade){
                    console.log('here')
                    market_setDraftOrder({ 
                        ...order,
                        live,
                        expire_datetime: expire_time,
                        grade:graded_order.grade,
                        bet_scope_grade: graded_order.bet_scope_grade,
                        jump_queue_probability: graded_order.jump_queue_probability,
                        buy_now_probability: graded_order.buy_now_probability
                    }, selling_position)
                } else {
                    market_setDraftOrder({
                        ...order,
                        live,
                        expire_datetime: expire_time
                    })
                }
            }
            return setTimeout(() => {
                setOrderState({ ...order_state, reviewed:true, loading:false })
            }, 200);
        }
        
        if(!order.expire_datetime){ return alert('Unable to process') }
       
        if(order.competition_id){ comps_setDraftWagerOrder({ ...new_order, title:order_title}) }
        else {
            if(!expire_time){ return alert('Unable to create order at this time') }
            await market_createOrder({ ...new_order, grade:undefined, title: order_title, league_id, live, market_type, promo_balance: use_promo_balance ? true : false, order_type: active_tab==='market'?'market':'limit', collar_pct:active_tab==='market'?default_collar_pct:0, expire_datetime: expire_time })
         }
        setOrderState({ ...order_state, loading:false })
        setExpireTime(undefined)
        market_setDraftOrder()
    }


    const renderQuickAmounts = (data:{ item:{ label: string, amount:number }, index:number }) => {
        return (
            <TouchableOpacity style={{ padding:3, paddingLeft:10, paddingRight:10, margin:3, width: (app_size.width - 60) / quick_amounts.length, borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:8 }}
            onPress={() => {
                if(data.item.label == 'all' && player?.type != 'premium'){ return alert('Only premium members are able to quick select all liquidity') }
                handleUpdateOrder('open_amt', data.item.amount)
            }}>
                <Text size={10} color={data.item.label=='all'?Colors.incentive.gold : Colors.brand.midnight} weight={data.item.label == 'all' ? 'bold': 'regular'} textAlign='center'>{data.item.label.toUpperCase()}</Text>
                {data.item.label == 'all' && player?.type != 'premium' ?
                <Icon name='lock' type='feather' color={Colors.incentive.gold} size={12} />
                :
                <Text size={12} color={data.item.label == 'all' ? Colors.incentive.gold : Colors.brand.midnight} weight={data.item.label == 'all' ? 'bold': 'regular'} textAlign='center'>{cl}{data.item.amount.toFixed(2)}</Text>
                }
            </TouchableOpacity>
        )
    }


    if(!order || (order.buy_sell_ind === 'sell' && !selling_position)){ return <></> }
    let cl = market_type === 'FOR_MONEY' ? '$' : 'E'
    if(order.competition_id){
        cl = 'E'
    }
    if(!order_title){ order_title == getOrderTitle(order) }
    let sale_value = 0;
    if(order.buy_sell_ind === 'sell'){ sale_value = order_calcSaleValue(order.odds, order_calcPotentialWinnings(selling_position?.odds, order.open_amt)) }
    const potential_winnings = order_calcPotentialWinnings(order.odds, order.open_amt)
    const potential_net_winnings = potential_winnings - ((potential_winnings - order.open_amt) * commission_pct)
    //Const Available Balances
    let available_balance = 0 
    let available_free_balance = 0
    let available_promo_balance = 0
    if(player_balance){
        available_balance = Math.floor(player_balance.balance * 100) / 100
        available_free_balance = Math.floor(player_balance.free_market_balance * 100) / 100
        available_promo_balance = Math.floor(player_balance.promo_balance * 100) / 100
    }

    //const bottom_tab_bar_height = useBottomTabBarHeight()

    if(order_state.saved_for_later && order){
        return (
            <TouchableOpacity 
                style={{ position:'absolute', bottom:bottom_tab_height, right:0, left:0, borderRadius:100 }} 
                onPress={() => setOrderState({ ...order_state, saved_for_later: false })}>
                    <View style={{ position:'absolute', bottom:0, right:0, left:0, borderTopRightRadius:22, borderTopLeftRadius:22, height:20, backgroundColor:Colors.shades.white, shadowColor: "rgba(221, 221, 221, 0.6)", shadowOffset: { width: 0, height: -4 }, shadowRadius: 4, shadowOpacity: 1  }} />
                <View style={{ marginBottom:5, padding:10, justifyContent:'center', alignItems:'center', alignSelf:'center', borderWidth:1, borderColor:Colors.brand.electric, backgroundColor:Colors.shades.white, borderRadius:100, shadowColor: "rgba(221, 221, 221, 0.6)", shadowOffset: { width: 0, height: 4 }, shadowRadius: 4, shadowOpacity: 1 }}>
                    <BetSlip color={Colors.brand.electric} size={20}/>
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <Modal isVisible={true} style={{ flex:1, margin:0, justifyContent: 'flex-end', alignItems:'center'}} animationIn='slideInUp' animationOut='slideOutDown' animationOutTiming={400}
        onBackdropPress={() => { setOrderState({ ...order_state, saved_for_later: true }); }} >
            <View style={{ width: app_size.width, backgroundColor:Colors.shades.white, borderTopRightRadius:42, borderTopLeftRadius:42, paddingBottom:35, paddingTop:10, maxHeight:app_size.height }}>
                <ScrollView style={{ flex:1 }}>
                {order.competition_id ?
                <View nativeID='competition_title' style={{ padding:10 }}>
                    <Text size={16} color={Colors.shades.black} weight='semibold' textAlign='center'>Competition Wager</Text>
                </View>
                :<></>}
                <View>
                    {order.event_type === 'team' && event ?
                    <View nativeID='order_event_title' style={{ padding:20 }}>
                        <EventInfoCard event={event} hide_info={true}/>
                    </View>
                    : order.event_type === 'match' && match ?
                    <View nativeID='order_event_title' style={{ padding:20 }}>
                        <Text size={16} color={Colors.shades.black} weight='semibold' textAlign='center'>{moment(match.scheduled_datetime).format('MMM DD YYYY')}</Text>
                        <Text size={20} color={Colors.shades.black} weight='semibold' textAlign='center' style={{ marginTop:10 }}>{match.match_title}</Text>
                    </View>
                    : order.event_type === 'tournament' && tournament ?
                    <View nativeID='order_event_title' style={{ padding:20 }}>
                        <Text size={16} color={Colors.shades.black} weight='semibold' textAlign='center'>{moment(tournament.scheduled_datetime).format('MMM DD YYYY')}</Text>
                        <Text size={20} color={Colors.shades.black} weight='semibold' textAlign='center' style={{ marginTop:10 }}>{tournament.tournament_name}</Text>
                    </View>
                    :<></>}
                    {!order.competition_id ?
                    <View>
                        <View nativeID='order_help' style={{ flexDirection:'row', justifyContent:'center' }}>
                            <TouchableOpacity style={{flexDirection:'row', alignItems:'center', justifyContent:'flex-end'}} onPress={() => window.open('https://support.bettoredge.com/hc/en-us/articles/1500010267062-What-s-an-Order-', '_blank')}>
                                <Icon containerStyle={{marginRight:3}} name='alert-circle' type='feather' color={Colors.brand.cobalt} size={14}/>
                                <Text size={14} color={Colors.brand.electric} weight='regular'>What is an order?</Text>
                            </TouchableOpacity>
                        </View>
                        {market_type == 'FREE' ?
                        <View style={{ margin:10, padding:10, borderRadius:8, backgroundColor:Colors.highlights.highlight300Faded }}>
                            <Text size={14} textAlign='center' weight='bold' color={Colors.brand.midnight}>DEMO MODE!</Text>
                            <Text style={{ marginTop:4 }} size={12} textAlign='center' color={Colors.brand.midnight}>If you are trying to place a REAL MONEY order, please switch modes at the top.</Text>
                        </View>
                        :<></>}
                    </View>
                    :<></>}
                </View>
                {!order.competition_id ?
                <View nativeID='order_type_toggle' style={{margin:16}}>
                    <View style={styles.toggleBox}>
                        <TouchableOpacity style={active_tab==='market'?styles.activeToggle:styles.inactiveToggle} onPress={() => { 
                            if(!latest_trade){ return alert('A limit order must be used to start market') }
                            setOrderState({ ...order_state, active_tab: 'market' })
                            }}>
                            <Text size={14} color={Colors.shades.black}>Market Order</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={active_tab==='limit'?styles.activeToggle:styles.inactiveToggle} onPress={() => { 
                            setOrderState({ ...order_state, active_tab: 'limit' })
                        }}>
                            <Text size={14} color={Colors.shades.black}>Limit Order</Text>
                        </TouchableOpacity>
                        <TouchableOpacity nativeID='h2h-trigger' style={active_tab==='h2h'?styles.activeToggle:styles.inactiveToggle} onPress={() => { 
                                if(order.buy_sell_ind==='sell'){ return alert('Cannot sell a position as a h2h challenge') }
                                return setOrderState({ ...order_state, active_tab: 'h2h'  })
                            }}>
                            <View style={{ flexDirection:'row', alignItems:'center' }}>
                                <Battle size={18} color={Colors.shades.black} />
                                <Text style={{ marginLeft:5 }} size={14} color={Colors.shades.black}>H2H</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
                :<></>}
                <View nativeID='order_info' style={{ margin:16, marginTop:0}}>
                    <View nativeID='order_title' style={{ flexDirection:'row' }}>
                        <Text size={14} color={Colors.shades.black} style={{ flex:1/3 }}>Outcome</Text>
                        <Text size={14} color={Colors.shades.black} textAlign='right' style={{ flex:2/3 }}>{order_title}</Text>
                    </View>
                    <View nativeID='order_probability' style={{ flexDirection:'row', marginTop:8 }}>
                        <Text size={14} color={Colors.shades.black} style={{ flex:1/3 }}>Odds (Probability)</Text>
                        <Text size={14} color={Colors.shades.black} textAlign='right' style={{ flex:2/3 }}>{odds_label} ({probability_label}%)</Text>
                    </View>
                    {false ?
                    <View nativeID='order_odds' style={{ flexDirection:'row', marginTop:8 }}>
                        <Text size={14} color={Colors.shades.black} style={{ flex:1/3 }}>Odds</Text>
                        <Text size={14} color={Colors.shades.black} textAlign='right' style={{ flex:2/3 }}>{odds_label}</Text>
                    </View>
                    :<></>}
                    {!order.competition_id ?
                    <View nativeID='order_collar_pct' style={{ flexDirection:'row', marginTop:8 }}>
                        <Text size={14} color={Colors.shades.black} style={{ flex:1/3 }}>Collar</Text>
                        <Text size={14} color={Colors.brand.slate} textAlign='right' style={{ flex:2/3 }}>{(default_collar_pct*100).toFixed()}%</Text>
                    </View>
                    :<></>}
                    {!order.competition_id && order.grade && reviewed ?
                     <View nativeID='order_grade' style={{ flexDirection:'row', marginTop:8 }}>
                        <Text size={14} color={Colors.shades.black} style={{ flex:1/3 }}>Grade</Text>
                        <View style={{flex:2/3, alignItems:'flex-end'}}>
                            <OrderGradeBar grade={order.grade} view_type='card' />
                        </View> 
                    </View>
                    :<></>}
                    <View style={{ marginTop:8, padding:10, backgroundColor:Colors.shades.shade600, flexDirection:'row', alignItems:'center', borderRadius:4 }}>
                        {unit_size ?
                        <Text style={{ flex:1 }} size={14} weight='semibold'>Default unit size is {unit_size}</Text>
                        :
                        <Text style={{ flex:1 }} size={14} weight='semibold'>You have no default unit size</Text>
                        }
                        <TouchableOpacity onPress={() => {
                            setOrderState({ ...order_state, saved_for_later:true })
                            navigate('SocialStack', { screen: 'UpdateProfile', initial:false, params: { tab: 'preferences' } })
                        }}>
                            <Text size={14} color={Colors.brand.electric}>{unit_size?'Change':'Set Amount'}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View nativeID='order_inputs' style={{ backgroundColor:Colors.shades.shade100, marginBottom:order_suggestion_height??0 }} onLayout={(ev) => {
                        const { x, y, height, width } = ev.nativeEvent.layout;
                        setInputLocation({ x, y, height, width })
                    }}>
                    <View style={{ flexDirection:'row', marginBottom:10, paddingTop:10, paddingRight:25, paddingLeft:25 }}>
                        { market?.var_1_required && active_tab !== 'market' && !order.competition_id ? 
                        <View style={{flex:1/2, marginRight:10}}>
                            <Text size={14} color={Colors.brand.slate}>{market.type.toUpperCase()}</Text>
                            <View style={{marginTop:5, flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderColor:Colors.accents.accent100, borderWidth:1, borderRadius:4, backgroundColor:Colors.shades.white}}>
                                <View style={{flex:1}}>
                                    <TextInput style={{flex:1, padding:10, textAlign:'center', backgroundColor:order.buy_sell_ind==='buy'?Colors.shades.white:Colors.shades.shade100 }} editable={order.buy_sell_ind === 'buy'?true:false} value={order.var_1.toString()} keyboardType='default' onChangeText={(e) => handleUpdateOrder('var_1', e)}/>
                                </View>
                            </View>
                        </View>
                        : <View style={{flex:1/2, marginRight:10}} /> }
                        { active_tab !== 'market' && price_preference === 'Probability' && !order.competition_id ?
                        <View style={{flex:1/2, marginLeft:10}}>
                            <Text style={Styles.textStyles.greyRegularLeft14}>PROBABILITY | ODDS</Text>
                            <View style={{marginTop:5, flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderColor:Colors.accents.accent100, borderWidth:1, borderRadius:4, backgroundColor:Colors.shades.white}}>
                                <View style={{justifyContent:'center', alignItems:'center', borderRightWidth:1, borderColor:Colors.accents.accent100, padding:10 }}>
                                    <Text size={16} color={Colors.shades.black} textAlign='center'>%</Text>
                                </View>
                                <View style={{flex:1}}>
                                    <TextInput style={{flex:1, padding:10, textAlign:'center'}} value={probability_label} keyboardType='decimal-pad' onChangeText={(e) => setOrderState({ ...order_state, probability_label: e, odds_label: market_getOddsLabel(order_calcAmericanOddsFromProbability(parseFloat(e)/100)) })}/>
                                </View>
                                <TouchableOpacity style={{justifyContent:'center', alignItems:'center', borderLeftWidth:1, borderColor:Colors.accents.accent100, padding:10, backgroundColor:Colors.shades.shade100}} onPress={() => setOrderState({ ...order_state, price_preference: 'odds' })}>
                                    <Text style={Styles.textStyles.blackRegularCenter16}>{market_getOddsLabel(parseFloat(odds_label))}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        : active_tab !== 'market' && !order.competition_id ?
                        <View style={{flex:1/2, marginLeft:10}}>
                            <Text style={Styles.textStyles.greyRegularLeft14}>ODDS | PROBABILITY</Text>
                            <View style={{marginTop:5, flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderColor:Colors.accents.accent100, borderWidth:1, borderRadius:4, backgroundColor:Colors.shades.white}}>
                                <View style={{flex:1}}>
                                    <TextInput style={{flex:1, padding:10, textAlign:'center'}} value={odds_label} keyboardType='default' onChangeText={(e) => setOrderState({ ...order_state, odds_label:e, probability_label: (order_calcProbabilityFromOdds(parseFloat(e))*100).toFixed(2) })}/>
                                </View>
                                <TouchableOpacity style={{justifyContent:'center', alignItems:'center', borderLeftWidth:1, borderColor:Colors.accents.accent100, padding:10, backgroundColor:Colors.shades.shade100}} onPress={() => setOrderState({ ...order_state, price_preference: 'Probability' })}>
                                    <Text style={Styles.textStyles.blackRegularCenter16}>{probability_label}%</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        :
                        <></> }
                    </View>
                    <View style={{ paddingBottom:10 }}>
                        <View style={{ flexDirection:'row', paddingRight:25, paddingLeft:25 }} >
                            <View style={{flex:1/2, marginRight:10}}>
                                <Text size={14} color={Colors.brand.slate}>QUANTITY</Text>
                                <View style={{marginTop:5, flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderColor:Colors.accents.accent100, borderWidth:1, borderRadius:4, backgroundColor:Colors.shades.white}}>
                                    <View style={{justifyContent:'center', alignItems:'center', borderRightWidth:1, borderColor:Colors.accents.accent100, padding:10 }}>
                                        <Text size={16} color={Colors.shades.black} textAlign='center'>{cl}</Text>
                                    </View>
                                    <View style={{flex:1}}>
                                        <TextInput style={{flex:1, padding:10, textAlign:'center'}} value={order.open_amt.toString()} keyboardType='decimal-pad' 
                                        onChangeText={(e) => { 
                                            handleUpdateOrder('open_amt', e)
                                        }}/>
                                    </View>
                                </View>
                            </View>
                            {order.buy_sell_ind === 'buy' ?
                            <View style={{flex:1/2, marginLeft:10}}>
                                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center' }} onPress={() => window.open('https://support.bettoredge.com/hc/en-us/articles/21017579316887-How-are-Earnings-Calculated', '_blank')}>
                                    <Text style={{ flex:1 }} size={14} color={Colors.brand.slate}>TO WIN</Text>
                                    <Icon name='alert-circle' type='feather' size={14} color={Colors.brand.slate} />
                                </TouchableOpacity>
                                <View style={{marginTop:5, flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderColor:Colors.accents.accent100, borderWidth:1, borderRadius:4, backgroundColor:Colors.shades.shade100}}>
                                    <View style={{justifyContent:'center', alignItems:'center', borderRightWidth:1, borderColor:Colors.accents.accent100, padding:10 }}>
                                        <Text size={16} color={Colors.shades.black} textAlign='center'>{cl}</Text>
                                    </View>
                                    <View style={{flex:1}}>
                                        <TextInput style={{flex:1, padding:10, textAlign:'center'}} value={potential_winnings.toString()} keyboardType='decimal-pad' editable={false}/>
                                    </View>
                                </View>
                            </View>
                            :
                            <View style={{flex:1/2, marginLeft:10}}>
                                <Text size={14} color={Colors.brand.slate}>CASH</Text>
                                <View style={{marginTop:5, flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderColor:Colors.accents.accent100, borderWidth:1, borderRadius:4, backgroundColor:Colors.shades.shade100}}>
                                    <View style={{justifyContent:'center', alignItems:'center', borderRightWidth:1, borderColor:Colors.accents.accent100, padding:10 }}>
                                        <Text size={16} color={Colors.shades.black} textAlign='center'>{cl}</Text>
                                    </View>
                                    <View style={{flex:1}}>
                                        <TextInput style={{flex:1, padding:10, textAlign:'center'}} value={sale_value.toFixed(2)} keyboardType='decimal-pad' editable={false}/>
                                    </View>
                                </View>
                            </View>
                            }
                        </View>
                        {isAuthenticated ?
                        <View style={{ flexDirection:'row', marginTop:3, paddingRight:25, paddingLeft:25 }}>
                            <View nativeID='balance_available' style={{ flex:1 }}>
                                {!order.competition_id && order.buy_sell_ind === 'buy' ?
                                <Text size={12} color={Colors.utility.success} weight='bold'>{cl}{market_type==='FOR_MONEY'?available_balance:available_free_balance} available</Text>
                                : !order.competition_id && order.buy_sell_ind === 'sell' && selling_position ?
                                <Text size={12} color={Colors.utility.success} weight='bold'>{Math.floor(selling_position.stake * 100) / 100} available</Text>
                                : order.competition_id && competition_player ?
                                <Text size={12} color={Colors.utility.success} weight='bold'>{Math.floor(competition_player.wager_based_balance * 100) / 100} available</Text>
                                :<></>}
                            </View>
                            {order.buy_sell_ind == 'buy' ?
                            <View nativeID='net_winnings'>
                                <Text size={12} color={Colors.brand.slate} weight='regular' textAlign='right'>EST. EARNINGS: {cl}{(potential_net_winnings - order.open_amt).toFixed(2)}</Text>
                            </View>
                            :<></>}
                        </View>
                        :<></>}
                    </View>
                    {order.buy_sell_ind == 'buy' && available_promo_balance > 0 ?
                    <TouchableOpacity style={{ backgroundColor:Colors.incentive.gold, padding:10, flexDirection:'row', alignItems:'center' }} onPress={() => {
                        if(use_promo_balance){
                            //Remove promo balance and open_amt
                            setUsePromoBalance(false);
                            handleUpdateOrder('open_amt', '0')
                        } else {
                            setUsePromoBalance(true);
                            handleUpdateOrder('open_amt', available_promo_balance);
                        }
                    }}>
                            <View style={{ marginRight:10, height:30, width:30, borderWidth:1, borderColor:Colors.brand.midnight, justifyContent:'center', alignItems:'center' }}>
                                {use_promo_balance ?
                                <View style={{ height:20, width:20, backgroundColor:Colors.brand.midnight }} />
                                :<></>}
                            </View>
                            <View>
                                <Text size={14} weight='bold' color={Colors.shades.white}>${available_promo_balance} Promotion Available</Text>
                                <Text style={{ marginTop:2}} size={12} color={Colors.shades.white} weight='regular'>Press / Click here to use promo balance</Text>
                            </View>
                        
                    </TouchableOpacity>
                    :<></>}
                    
                </View> 
                <View style={{ padding:2, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.shade600, borderTopWidth:1, borderBottomWidth:1, borderColor:Colors.shades.shade600 }}>
                    <FlatList
                        data={quick_amounts}
                        keyExtractor={(item) => item.label}
                        renderItem={renderQuickAmounts}
                        horizontal
                    />
                </View>
                {!order.competition_id ?
                <View style={{padding:10, paddingLeft:30, paddingRight:30}}>
                    <ExpirationDatePicker isVisible={true} event_type={order.event_type} event={event} tournament={tournament} match={match} onSelect={(ed) => {
                            setExpireTime(ed) 
                        }} />
                </View> 
                :<></>}
                {active_tab === 'h2h' ?
                <View style={{margin:10, padding:10, borderRadius:8, backgroundColor:Colors.shades.shade600}}>
                    <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.midnight} weight='bold'>Send to a player</Text>
                    <PlayerSelector
                        player_id={order.player_id}
                        init_player={challenger}
                        onPlayerSelect={(player) => {
                            market_setDraftOrder({ ...order, h2h_id: player.player_id, h2h_user:player }, selling_position)}
                        }
                    />
                </View>
                :<></>}
                <View>
                    <View nativeID='order_buttons' style={{flex:1, padding:10, paddingLeft:15, paddingRight:15, flexDirection:'row', alignItems:'center'}}>
                        <TouchableOpacity 
                            style={{ flex:1/3, borderWidth:1, borderColor:Colors.brand.electric, padding:10, borderRadius:4, marginRight:4 }} 
                            onPress={() => {
                                market_setDraftOrder()
                                setExpireTime(undefined)
                                setOrderState(DEFAULT_ORDER_STATE)
                            }}>
                            <Text size={14} color={Colors.brand.electric} weight='bold' textAlign='center'>Cancel</Text>
                        </TouchableOpacity>
                        {isAuthenticated && player ?
                        <View style={{ flex:2/3 }}>
                            {order.market_type === 'FREE' || (market_type === 'FOR_MONEY' && player.vouched_status === 'verified') ?
                            <TouchableOpacity 
                                style={{ flex:1, backgroundColor:reviewed?Colors.utility.success:Colors.brand.electric, padding:10, borderRadius:4, marginLeft:4 }} 
                                disabled={loading || location.loading}
                                onPress={() => submitOrder()}>
                                {loading || location.loading ? 
                                <View style={{ flexDirection:'row', alignItems:'center', justifyContent:'center' }}>
                                    <ActivityIndicator size='small' color={Colors.shades.white} />
                                    <Text style={{ marginLeft:5 }} size={14} color={Colors.shades.white} weight='regular'>{location.loading ? 'Verifying Location':'Reviewing'}</Text>
                                </View>
                                :
                                <Text style={Styles.textStyles.whiteBoldCenter14}>{reviewed?'Submit':'Review'}</Text>
                                }
                            </TouchableOpacity>
                            :
                            <TouchableOpacity style={{ flex:1, backgroundColor:Colors.brand.electric, padding:10, borderRadius:4, marginLeft:4 }} onPress={() => handleVerifyNow()}>
                                <Text style={Styles.textStyles.whiteBoldCenter14}>Verify Now</Text>
                            </TouchableOpacity>
                            }
                        </View>
                        
                        :
                        <TouchableOpacity style={{ flex:2/3, backgroundColor:Colors.utility.success, padding:10, borderRadius:4, marginLeft:4 }} onPress={() => handleSignIn()}>
                            <Text style={Styles.textStyles.whiteBoldCenter14}>Sign In</Text>
                        </TouchableOpacity>
                        }
                    </View>
                    <TouchableOpacity style={{ padding:10, justifyContent:'center', alignItems:'center' }} onPress={() => setOrderState({ ...order_state, saved_for_later:true })}>
                        <Text size={14} color={Colors.utility.error}>SAVE FOR LATER</Text>
                    </TouchableOpacity>
                </View>
                </ScrollView>
                {order.grade && order.grade >= 120 && !order.live && !suggestion_hidden?
                <View style={{ position:'absolute', top:0, right:0, left:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end', alignItems:'center' }}>
                    <View style={{ backgroundColor:Colors.shades.white, borderRadius:8, margin:20, padding:10 }}>
                        <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign='center'>Very Generous Offer!</Text>
                        <Text size={14} color={Colors.brand.midnight} weight='regular' textAlign='center' style={{ padding:10 }}>This order seems like it could be an error.  Are you sure you'd like to place it?</Text>
                        <View style={{ flexDirection:'row' }}>
                            <TouchableOpacity native_id='ebp_cancel' event_properties={{ ...order }} style={{ flex:1, margin:3, borderWidth:1, borderColor:Colors.utility.error, padding:10 }} onPress={() => {
                                setOrderState(DEFAULT_ORDER_STATE);
                                setExpireTime(undefined)
                                market_setDraftOrder()
                            }}>
                                <Text size={14} color={Colors.utility.error} weight='semibold' textAlign='center'>No - Cancel</Text>
                            </TouchableOpacity>
                            <TouchableOpacity native_id='ebp_continue' event_properties={{ ...order }} style={{ flex:1, margin:3, borderWidth:1, borderColor:Colors.utility.success, padding:10 }} onPress={() => {
                                setOrderState({ ...order_state, suggestion_hidden: true })
                            }}>
                                <Text size={14} color={Colors.utility.success} weight='semibold' textAlign='center'>Yes - Continue</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
                :
                <></>}
                {!order.competition_id && !suggestion_hidden && active_tab == 'market' && order.buy_now_probability && (!order.grade || order.grade < 120) ?
                <OrderSuggestionCard
                    top={input_location.y + input_location.height}
                    onDeclineSuggestion={() => {
                        setOrderSuggestionHeight(0)
                        setOrderState({ ...order_state, reviewed:true, suggestion_hidden:true })
                    }}
                    onAcceptSuggestion={(new_order) => {
                        handlePriceChange(new_order)
                    }}
                    order={order}
                    onHeightChange={(height) => setOrderSuggestionHeight(height)}
                />
                :<></>}
            </View>
        </Modal>
    )
}

const styles = {
    toggleBox: { flex:1, padding:2, flexDirection:'row', borderRadius: 6, backgroundColor: "#e9e9e9" },
    modalStyle: { backgroundColor: Colors.shades.white, borderTopRightRadius: 42, borderTopLeftRadius: 42, paddingBottom:35, paddingTop:10 },
    inactiveToggle: { flex:1, padding:12, justifyContent:'center', alignItems:'center' },
    activeToggle: { flex:1, padding:12, justifyContent:'center', alignItems:'center' , borderRadius: 5, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.2)", shadowOffset: { width: 0, height: 1 }, shadowRadius: 0, shadowOpacity: 1 }
}

const mapStateToProps = (state:any) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        player: state.player.player,
        order: state.market.draft_order,
        selling_position: state.market.selling_position,
        player_balance: state.player.player_balance,
        player_settings: state.player.player_settings_list.player_settings,
        market_type: state.auth.market_type,
        settings: state.player.settings,
        markets: state.market.markets,
        app_size: state.util.app_size,
        challenger: state.market.challenger,
        latest_trades_list: state.market.latest_trades[`${state.market.draft_order?.event_type}:${state.market.draft_order?.event_id}`],
        event: state.event.events[state.market.draft_order?.event_id??''],
        tournament: state.event.tournaments[state.market.draft_order?.event_id??''],
        match: state.event.matches[state.market.draft_order?.event_id??''],
        athlete:state.event.athletes_list.athletes.find(a => a.athlete_id == state.market.draft_order?.side_id),
        exotic:state.event.exotics_list.exotics.find(e => e.exotic_id == state.market.draft_order?.side_id),
        team:state.event.teams_list.teams.find(t => t.team_id == state.market.draft_order?.side_id)
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        market_setDraftOrder: (order?:OrderProps, position?:PositionProps) => dispatch(market_setDraftOrder(order, position)),
        market_createOrder: (order:OrderProps) => dispatch(market_createOrder(order)),
        comps_setDraftWagerOrder: (order?:OrderProps) => dispatch(comps_setDraftWagerOrder(order)),
        event_getAthleteByAthleteId: (athlete_id:string) => dispatch(event_getAthleteByAthleteId(athlete_id)),
        showAuthenticate: () => dispatch({ type:V1_SHOW_AUTHENTICATE, show_authenticate: { visible:true, default_view:'login' } }),
        showWallet: () => dispatch({ type:V1_SHOW_WALLET, show_wallet: { visible:true } }),
        showVouch: () =>  dispatch({ type: V1_SHOW_PROFILE, show_profile: { visible: true, profile_attribute: 'vouch' } })
    }
}

const areEqual = (prevProps:OrderModalProps, nextProps:OrderModalProps) => {
    if(prevProps.event != nextProps.event){ return false }
    if(prevProps.tournament != nextProps.tournament){ return false }
    if(prevProps.match != nextProps.match){ return false }
    if(prevProps.market_type != nextProps.market_type){ return false }
    if(prevProps.order != nextProps.order){ return false }
    if(prevProps.challenger?.player_id != nextProps.challenger?.player_id){ return false }
    if(prevProps.athlete?.athlete_id != nextProps.athlete?.athlete_id){ return false }
    if(prevProps.selling_position != nextProps.selling_position){ return false }
    if(prevProps.app_size.width != nextProps.app_size.width){ return false }
    if(prevProps.player_balance != nextProps.player_balance){ return false }
    return true
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OrderModal, areEqual))