import axios from 'axios';
import getEnvVars from '../../env';
import { segEventTrack } from '../../utils/analytics/segment';
import { getPlayerLocation } from '../../utilsv1/location';
import { errHandler } from '../errHandler';
import { LocationProps } from '../types/auth_svc';
import { BestAvailableOrderProps, EventOrderStatProps, MarketResolveProps, OrderProps, OrderResponseProps, PlayerCommissionRebateProps, PlayerFilterProps, PositionProps, TradeProps } from '../types/mk_svc';
import { event_getBulkEvents } from './events';
import { socket_connect } from './socket';
import { V1_LOAD_ALERT, V1_LOAD_ALERT_ORDER, V1_LOAD_BEST_AVAILABLE, V1_LOAD_DRAFT_ORDER, V1_LOAD_EVENTS, V1_LOAD_FILTERED_ORDERS, V1_LOAD_H2H_REQUESTS, V1_LOAD_LATEST_TRADES, V1_LOAD_LOCATION, V1_LOAD_MARKETS, V1_LOAD_MATCHES, V1_LOAD_ORDERS, V1_LOAD_ORDER_BOOK, V1_LOAD_ORDER_STATS, V1_LOAD_PLAYER_HEDGES, V1_LOAD_PLAYER_ORDERS, V1_LOAD_POPULAR_EVENT_IDS, V1_LOAD_SUGGESTED_MARKETS, V1_LOAD_TOURNAMENTS, V1_LOAD_TRADES, V1_LOAD_VIEWING_ORDERS, V1_LOAD_VIEWING_PLAYER_ORDERS, V1_SHOW_LOCATION, V1_UPDATE_LATEST_TRADES, V1_UPDATE_MARKET_RESOLVE, V1_UPDATE_ORDER, V1_UPDATE_ORDER_SUGGESTIONS, V1_UPDATE_PLAYER_FILTERS, V1_UPDATE_PLAYER_ORDER } from './types';
import { EventProps, MatchProps, TournamentProps } from '../types/sr_svc';
import { util_dictionizeArray } from './utils';
const { MK_SVC_API } = getEnvVars()



export const market_getMarkets = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/markets/all?status=active`)
            dispatch({ type: V1_LOAD_MARKETS, markets: resp.data.markets })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getBestAvailable = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/available`);
            //First, lets go aheady and just load the contests from best available right away
            dispatch({ type:V1_LOAD_EVENTS, events: util_dictionizeArray(resp.data.events, 'event_id') });
            dispatch({ type:V1_LOAD_TOURNAMENTS, tournaments: util_dictionizeArray(resp.data.tournaments, 'tournament_id') });
            dispatch({ type: V1_LOAD_MATCHES, matches: util_dictionizeArray(resp.data.matches, 'match_id') });

            const { best_available, order_stats, latest_trades } = formatBestAvailable(resp.data)
            
            dispatch({ type: V1_LOAD_BEST_AVAILABLE, best_available, initial:true })
            dispatch({ type: V1_LOAD_ORDER_STATS, order_stats, initial:true })
            dispatch({ type: V1_LOAD_LATEST_TRADES, latest_trades, initial:true })
        } catch (e) {
            console.log(e)
        }
    }
}

export const formatBestAvailable = (ba_response:{ events:EventProps[], tournaments:TournamentProps[], matches:MatchProps[] }) => {
    let best_available:{ [key:string]: BestAvailableOrderProps[] } = {}
    let order_stats: { [key:string]: EventOrderStatProps[] } = {}
    let latest_trades: { [key:string]: TradeProps[] } = {}

    ba_response.events.map((e:EventProps) => {
        if(!e.supported_markets){ return }
        let ao:BestAvailableOrderProps[] = [], os:EventOrderStatProps[] = [], lt:TradeProps[] = []
        let supported_markets = e.supported_markets.filter(m => m)
        supported_markets.map(m => {
            m.available_orders?.map(o => ao.push(o))
            m.order_stats?.map(s => os.push(s))
            m.latest_trades?.map(t => lt.push(t))
        });
        best_available[`team:${e.event_id}`] = ao
        order_stats[`team:${e.event_id}`] = os
        latest_trades[`team:${e.event_id}`] = lt
    })
    ba_response.tournaments.map((e:TournamentProps) => {
        if(!e.supported_markets){ return }
        let ao:BestAvailableOrderProps[] = [], os:EventOrderStatProps[] = [], lt:TradeProps[] = []
        let supported_markets = e.supported_markets.filter(m => m)
        supported_markets.map(m => {
            m.available_orders?.map(o => ao.push(o))
            m.order_stats?.map(s => os.push(s))
            m.latest_trades?.map(t => lt.push(t))
        });
        best_available[`tournament:${e.tournament_id}`] = ao
        order_stats[`tournament:${e.tournament_id}`] = os
        latest_trades[`tournament:${e.tournament_id}`] = lt

    })
    ba_response.matches.map((e:MatchProps) => {
        if(!e.supported_markets){ return }
        let ao:BestAvailableOrderProps[] = [], os:EventOrderStatProps[] = [], lt:TradeProps[] = []
        let supported_markets = e.supported_markets.filter(m => m)
        supported_markets.map(m => {
            m.available_orders?.map(o => ao.push(o))
            m.order_stats?.map(s => os.push(s))
            m.latest_trades?.map(t => lt.push(t))
        });
        best_available[`match:${e.match_id}`] = ao
        order_stats[`match:${e.match_id}`] = os
        latest_trades[`match:${e.match_id}`] = lt
    })
    return { best_available, order_stats, latest_trades }
}


export const market_gradeOrder = async(order:OrderProps) => {
    try {
        const resp = await axios.post(`${MK_SVC_API}/v1/orders/order/grade`, { order })
        return resp.data.order
    } catch (e) {
        return order
    }
}

export const market_getSuggestedMarkets = (market_ids:string[], offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/markets/suggested`, { market_ids, offset })
            dispatch({ type: V1_LOAD_SUGGESTED_MARKETS, suggested_markets: resp.data.market_stats })
        } catch (e) {
            console.log(e)
        }
    }
}


export const market_createOrder = (order:OrderProps) => {
    return async(dispatch:any, getState:any) => {
        try {
            const location: { region?:string, coordinates?:any, location?:LocationProps } = getState().util.location.location
            if(order.market_type === 'FOR_MONEY'){
                if(!location?.region || !location.coordinates || !location.location){ return dispatch({ type:V1_SHOW_LOCATION, visible:true }) }
                if(!location.location.legal_ind){ return alert(`We are not able to process real money orders in ${location.location.label} at this time.  Please check back again later.`) }
            }
            //Get the users location
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/order/create`, { order: { ...order, region: location?.region, location: location?.coordinates } })
            segEventTrack({ event: 'Create Order', properties: { order: { ...resp.data.order } } })

            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Success', type:'success', body:'Successfully created order', data: resp.data.order } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:'Unable to create order at this time' } })
            console.log(e.message)
        }
    }
}

export const market_tipOrder = (order_id:string, tip_pct:number, tip_type:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/order/tip`, { order_id, tip_pct, tip_type })
            dispatch({ type: V1_UPDATE_ORDER, order: resp.data.order })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Success', type:'success', body:'All Set! We will send the tip if this bet wins!', data: resp.data.order } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:'Unable to process tip' } })
            console.log(e)
        }
    }
}

export const market_reverseOrder = async(order:OrderProps):Promise<OrderProps | undefined> => {
    try {
        const resp = await axios.post(`${MK_SVC_API}/v1/orders/order/reverse`, { order })
        return resp.data.order
    } catch (e) {
        return undefined
    }
}

export const market_cancelOrder = (order_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/order/cancel`, { order_id})
            dispatch({ type:V1_UPDATE_PLAYER_ORDER, order: resp.data.order })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Success', type:'success', body:'Successfully cancelled order', data: resp.data.order } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:'Unable to process cancellation', data: {} } })
            console.log(e)
        }
    }
}

export const market_bulkCancelOrder = (order_ids:string[]) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/bulk/cancel`, { order_ids })
            resp.data.orders.map((o:OrderProps) => {
                dispatch({ type: V1_UPDATE_PLAYER_ORDER, order:o })
            })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Success', type:'success', body:'Successfully cancelled orders', data: resp.data.orders } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:'Unable to process cancellation', data: {} } })
            console.log(e)
        }
    }
}

export const market_bulkGetOrders = (order_ids:string[]) => {
    return async(dispatch:any, getState:any) => {
        try {

            let existing_orders:OrderProps[] = []
            let needed_orders:string[] = []
            order_ids.map(id => {
                let existing_player_order = getState().player.orders.orders.find((o:OrderProps) => o.order_id == id)
                if(existing_player_order){ return existing_orders.push(existing_player_order) }
                let existing_view = getState().social.viewing_orders.find((o:OrderProps) => o.order_id == id)
                if(existing_view){ return } //Already in viewing orders
                return needed_orders.push(id)
            })
            if(existing_orders.length > 0){
                dispatch({ type: V1_LOAD_VIEWING_ORDERS, orders:existing_orders })
            }
            if(needed_orders.length === 0){ return }
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/bulk/get`, { order_ids:needed_orders })
            dispatch({ type:V1_LOAD_VIEWING_ORDERS, orders:resp.data.orders })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getLatestTradesByEventId = (event_id:string, event_type:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/trades/event/latest/${event_id}/${event_type}`)
            dispatch({ type: V1_LOAD_LATEST_TRADES, latest_trades: {[ `${event_type}:${event_id}` ] : resp.data.trades } })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getLatestTradesByEventIds = (event_ids:string[], event_type:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/trades/event/latest/bulk/get`, { event_ids, event_type })
            let latest_trades: { [key:string]: TradeProps } = {}
            event_ids.map(id => {
                let trades = resp.data.trades.filter((t:TradeProps) => t.event_id == id && t.event_type == event_type)
                latest_trades[`${event_type}:${id}`] = trades
            })
            dispatch({ type: V1_LOAD_LATEST_TRADES, latest_trades })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getTradesByEventIdAndMarketId = (event_id:string, event_type:string, market_id:string, offset:number, var_1:number, side_id?:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/trades/event/all/${event_id}/${event_type}?market_id=${market_id}&offset=${offset}&side_id=${side_id}&var_1=${var_1}`)
            dispatch({ type: V1_LOAD_TRADES, trades: resp.data.trades, offset })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getOrdersByEventIdAndMarketId = (event_id:string, market_id:string, status:string, offset:number, side_id?:string, reverse?:boolean, sort?:string) => {
    return async(dispatch:any) => {
        try {          
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/event/${event_id}?market_id=${market_id}&status=${status}&offset=${offset}&side_id=${side_id}&reverse=${reverse}&sort=${sort}`)
            dispatch({ type: V1_LOAD_ORDERS, orders: resp.data.orders, offset })
        } catch (e) {
            console.log(e)
        }
    }
}


export const market_getOrderBook = (event_id:string, event_type:string, market_id:string, var_1:number, offset:number, side_id?:string, exclude_book?:boolean) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/book?event_id=${event_id}&event_type=${event_type}&market_id=${market_id}&var_1=${var_1}&offset=${offset}&side_id=${side_id}`)
            dispatch({ type: V1_LOAD_ORDERS, orders:resp.data.orders, offset })
            if(!exclude_book){
                dispatch({ type: V1_LOAD_ORDER_BOOK, order_book:resp.data.order_book })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getOrderFiltersByPlayerId = (player_id:string, status?:string, pinned?:boolean) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/filters/player/${player_id}?status=${status}&pinned=${pinned}`)
            dispatch({ type: V1_UPDATE_PLAYER_FILTERS, player_filters:resp.data.player_filters })
        } catch (e) {
            console.log(e)
        }
    }
}


export const market_getPlayerFilterById = (player_filter_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/filters/filter/${player_filter_id}`)
            dispatch({ type: V1_UPDATE_PLAYER_FILTERS, player_filters:[resp.data.player_filter] })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_runPlayerFilter = (player_filter_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/filters/run/${player_filter_id}`)
            dispatch({ type: V1_LOAD_FILTERED_ORDERS, order_ids: resp.data.orders.map((o:OrderProps) => o.order_id) })
            dispatch({ type: V1_LOAD_ORDERS, orders: resp.data.orders, offset:0 })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_createPlayerFilter = (player_filter:PlayerFilterProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/filters/player/create`, { player_filter })
            dispatch({ type: V1_UPDATE_PLAYER_FILTERS, player_filters: [resp.data.player_filter] })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Filter Created!', type:'success', body:`You successfully created a quick filter!` } })

        } catch (e) {
            console.log(e)
        }
    }
}

export const market_deletePlayerFilter = (player_filter_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/filters/player/delete`, { player_filter_id })
            dispatch({ type: V1_UPDATE_PLAYER_FILTERS, player_filters: [resp.data.player_filter] })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Filter Deleted!', type:'success', body:`You successfully deleted a quick filter!` } })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_updatePlayerFilter = (player_filter:PlayerFilterProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/filters/player/update`, { player_filter })
            dispatch({ type: V1_UPDATE_PLAYER_FILTERS, player_filters: [resp.data.player_filter] })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Filter Updated!', type:'success', body:`You successfully updated a quick filter!` } })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_saveOrderResponse = (order_response:OrderResponseProps) => {
    return async(dispatch:any, getState:any) => {
        try {
            let filtered_orders:string[] = getState().market.filtered_orders
            filtered_orders = filtered_orders.filter(o => o != order_response.order_id)
            await axios.post(`${MK_SVC_API}/v1/filters/order/response/create`, { order_response })
            dispatch({ type: V1_LOAD_FILTERED_ORDERS, order_ids: filtered_orders })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_rejectOrder = async(order_id:string) => {
    try {
        await axios.post(`${MK_SVC_API}/v1/orders/order/reject`, { order_id })
        return
    } catch (e) {
        console.log(e)
        return
    }
}

export const market_setDraftOrder = (order?:OrderProps, position?:PositionProps) => {
    return async(dispatch:any, getState:any) => {
        try {
            if(!order){ return dispatch({ type: V1_LOAD_DRAFT_ORDER, order:undefined}) }

            //If the order is a buy order and there is no existing order, we need to update the open_amt to a default unit size
            if(order.buy_sell_ind == 'buy'){
                const existing_order = getState().market.draft_order
                if(!existing_order){
                    //Find the unit size setting
                    const unit_size_setting = getState().player.settings.find(s => s.setting == 'unit_size')
                    if(unit_size_setting){
                        const player_setting = getState().player.player_settings_list.player_settings.find(ps => ps.setting_id == unit_size_setting.setting_id)
                        if(player_setting?.selected_option){
                            order.open_amt = parseFloat(player_setting.selected_option)
                        }
                    }
                }
            }

            //const resp = await axios.post(`${MK_SVC_API}/v1/orders/order/validate`, { order })
            dispatch({ type: V1_LOAD_DRAFT_ORDER, order, position })
        } catch (e) {
            console.log(e)
        }
        
    }
}

export const market_getOrdersByPlayerId = (player_id:string, offset:number, me:boolean, resolution_status?:string, event_id?:string, event_type?:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/player/${player_id}?resolution_status=${resolution_status}&offset=${offset}&event_id=${event_id}&event_type=${event_type}`)
            if(me){
                dispatch({ type: V1_LOAD_PLAYER_ORDERS, orders: resp.data.orders, offset })
            } else {
                dispatch({ type: V1_LOAD_VIEWING_PLAYER_ORDERS, orders: resp.data.orders, offset })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getHedgesByPlayerId = (player_id:string, event_id?:string, event_type?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/hedges/player/${player_id}?event_id=${event_id}&event_type=${event_type}`)
            dispatch({ type: V1_LOAD_PLAYER_HEDGES, hedges: resp.data.hedges, orders: [] })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getHedgeByHedgeId = (hedge_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/hedges/hedge/${hedge_id}`)
            dispatch({ type: V1_LOAD_PLAYER_HEDGES, hedges: [resp.data.hedge], orders: resp.data.orders })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_processHedge = (hedge_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/hedges/hedge/process`, { hedge_id })
            dispatch({ type: V1_LOAD_PLAYER_HEDGES, hedges: [resp.data.hedge], orders: [] })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Hedge Processed', type:'success', body:`You successfully hedged a market! Cash of $${resp.data.hedge.cash_rcvd.toFixed(2)} has been added to your wallet` } })
        } catch (e) {
            console.log(e)
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Unable to process hedge', type:'error', body:`Unable to process hedge.  Please reload page and try again later` } })

        }
    }
}

export const market_getOrderHistoryByPlayerId = (player_id:string, range:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/history/player/${player_id}?range=${range}`)
            dispatch({ type: V1_LOAD_PLAYER_ORDERS, orders: resp.data.orders, offset: 0 })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getOrderByOrderId = (order_id:string, reverse?:boolean) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/order/${order_id}?reverse=${reverse}`)
            if(resp.data.order.reversed){
                dispatch({ type: V1_LOAD_FILTERED_ORDERS, order_ids: [resp.data.order.order_id] })
                dispatch({ type: V1_LOAD_ORDERS, orders: [resp.data.order], offset:0 })
            } else {
                dispatch({ type:V1_UPDATE_PLAYER_ORDER, order:resp.data.order })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getOrderByIdForDeepLink = async(order_id:string):Promise<OrderProps | undefined> => {
    try {
        const resp = await axios.get(`${MK_SVC_API}/v1/orders/order/${order_id}?reverse=true`)
        return resp.data.order
    } catch (e) {
        console.log(e)
        return undefined
    }
}

export const market_getH2HRequestsByH2HId = (h2h_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/h2h/requested/${h2h_id}`)
            dispatch({ type: V1_LOAD_H2H_REQUESTS, orders: resp.data.orders })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getH2HHistoryByH2HId = async(h2h_id:string):Promise<OrderProps[]> => {
    try {
        const resp = await axios.get(`${MK_SVC_API}/v1/orders/h2h/history/${h2h_id}`)
        return resp.data.orders
    } catch (e) {
        console.log(e)
        return []
    }
}

export const market_acceptH2HChallenge = (order_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/h2h/accept`, { order_id })
            dispatch({ type: V1_UPDATE_PLAYER_ORDER, order: resp.data.order })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Challenge Accepted!', type:'success', body:'Good luck!' } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:'Unable to accept this challenge' } })
            console.log(e)
        }
    }
}

export const market_declineH2HChallenge = (order_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/h2h/decline`, { order_id })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Challenge Declined!', type:'success', body:'Successfully declined challenge request' } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:'Unable to decline this challenge' } })
            console.log(e)
        }
    }
}

export const market_getOrderStatsByEvent = (event_id:string, event_type:string, market_id?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/stats/event/${event_id}/${event_type}?market_id=${market_id}`)
            dispatch({ type: V1_LOAD_ORDER_STATS, order_stats: { [`${event_type}:${event_id}`] : resp.data.order_stats }})
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getOrderStatsByEventIds = (event_ids:string[], event_type:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/stats/bulk/get`, { event_ids, event_type })

            let os:{ [key:string]:EventOrderStatProps[] } = {}
            resp.data.order_stats.map((s:EventOrderStatProps) => {
                if(!os[`${s.event_type}:${s.event_id}`]){ os[`${s.event_type}:${s.event_id}`] = [s] }
                os[`${s.event_type}:${s.event_id}`] = os[`${s.event_type}:${s.event_id}`].concat(s)
            })

            dispatch({ type: V1_LOAD_ORDER_STATS, order_stats:os })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_getOrderSuggestions = (order_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/order/suggest/${order_id}`)
            const { buy_now_probability, jump_queue_probability } = resp.data;
            dispatch({ type: V1_UPDATE_ORDER_SUGGESTIONS, order_id, jump_queue_probability, buy_now_probability })
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_modifyOrder = (order_id:string, attribute:string, value:string|number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${MK_SVC_API}/v1/orders/order/modify`, { order_id, attribute, value })
        } catch (e) {
            console.log(e)
        }
    }
}


export const market_getActiveOrderStatsByPlayerId = (player_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${MK_SVC_API}/v1/orders/stats/player/${player_id}`)
        } catch (e) {
            console.log(e)
        }
    }
}

export const market_clearOrderAlert = () => {
    return(dispatch:any) => {
        dispatch({ type: V1_LOAD_ALERT_ORDER, order:undefined })
    }
}

export const market_updateMarketResolve = (market_resolve?:MarketResolveProps) => {
    return(dispatch:any) => {
        dispatch({ type: V1_UPDATE_MARKET_RESOLVE, market_resolve })
    }
}

export const market_resolveMarket = (market_resolve:MarketResolveProps) => {
    return async(dispatch:any) => {
        try {
            await axios.post(`${MK_SVC_API}/v1/markets/market/resolve`, { market_resolve })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Success', type:'success', body:'Successfully resolved market', data: {} } })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
} 



export const market_getUnreviewedOrders = async():Promise<OrderProps[]> => {
    try {
        const resp = await axios.get(`${MK_SVC_API}/v1/orders/results/me`)
        return resp.data.orders    
    } catch (e) {
        return []
    }
}

export const market_reviewOrders = async(order_ids:string[]):Promise<void> => {
    try {
        await axios.post(`${MK_SVC_API}/v1/orders/results/review`, { order_ids })
    } catch (e) {
        return
    }
}



//Rebates
export const market_getMyCollectableRebates = async():Promise<PlayerCommissionRebateProps[]> => {
    try {
        const resp = await axios.get(`${MK_SVC_API}/v1/orders/rebates/me`)
        return resp.data.player_commission_rebates
    } catch (e) {
        return []
    }
}

export const market_getPlayerCommissionRebateById = async(player_commission_rebate_id:string):Promise<PlayerCommissionRebateProps|undefined> => {
    try {
        const resp = await axios.get(`${MK_SVC_API}/v1/orders/rebate/${player_commission_rebate_id}`)
        return resp.data.player_commission_rebate
    } catch (e) {
        return undefined
    }
}

export const market_collectRebate = async(player_commission_rebate_id:string):Promise<PlayerCommissionRebateProps|undefined> => {
    try {
        const resp = await axios.post(`${MK_SVC_API}/v1/orders/rebate/collect`, { player_commission_rebate_id })
        return resp.data.player_commission_rebate
    } catch (e) {
        console.log(e)
        return undefined
    }
}