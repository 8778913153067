import { UtilReducerProps, V1_AUTHENTICATE_APP, V1_CLEAR_HELP, V1_FULFILL_ALERT_CLOSED, V1_LOAD_APP_ALERTS, V1_LOAD_HELP, V1_LOAD_HELP_SOFT_PROMPTS, V1_LOAD_LOCATION, V1_OBSERVE_APP, V1_SELECT_ACTION, V1_SHOW_AUTHENTICATE, V1_SHOW_CHECKOUT, V1_SHOW_LOCATION, V1_SHOW_PROFILE, V1_SHOW_WALLET, V1_UPDATE_APP_ALERT, V1_UPDATE_APP_SIZE, V1_UPDATE_AUDIO_HEIGHT, V1_UPDATE_BOTTOM_TAB_HEIGHT, V1_UPDATE_CACHE_REQUEST, V1_UPDATE_CATALOG_VISIBLE, V1_UPDATE_DEFAULT_LINES_VISIBLE, V1_UPDATE_DISTINCT_ID, V1_UPDATE_HEADER_HEIGHT, V1_UPDATE_HELMET, V1_UPDATE_INITIAL_URL, V1_UPDATE_INSTALL_PROMPT, V1_UPDATE_ITEM_ORDER, V1_UPDATE_LAST_ROUTE, V1_UPDATE_SESSION_ID, V1_UPDATE_SOCKET_CONNECTED, V1_UPDATE_WALLET_VISIBILE, V1_VIEW_ALL_PROMPTS, V1_VIEW_PROMPT } from "../actionsv1/types"


const DEFAULT_STATE:UtilReducerProps = {
    app_size: { width:400, height:800 },
    socket_connected: false,
    cache_requests: [],
    audio_height: 0,
    inital_url: undefined,
    location_visible:false,
    location: {
        permission: {
            checked:false
        },
        location: {
            loading: false,
            address_results: [],
            coordinates: {}
        }
    },
    wallet_visible: false,
    helmet_state: {
        title: 'BettorEdge Social Betting Marketplace',
        description: 'Bet against others and not the house with no vig!',
        image: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1710867433/be_logo_wide_og_ifi4od.webp',
        additional_tags: [],
        updated:1
    },
    bottom_tab_height: 0,
    item_order:undefined,
    analytics_session_id: undefined,
    distinct_id:undefined,
    default_lines_visible: false,
    catalog_visible:false,
    help_soft_prompts:[],
    viewed_prompts: [],
    fulfill_alert_closed:false,
    help: undefined,
    app_alerts_list: {
        updated: 0,
        app_alerts: []
    },
    deferred_prompt:undefined,
    header_height: 0,
    show_authenticate: {
        visible:false,
    },
    show_profile: {
        visible:false,
        show_welcome:false
    },
    show_wallet: {
        visible:false
    },
    show_checkout: {
        visible: false
    },
    analytic_tokens: {
        set: false,
        distinct_id: '',
        session_id: ''
    },
    auth_tokens: {},
}

const utilReducer = (state = DEFAULT_STATE, action:any):UtilReducerProps => {
    switch(action.type){
        case V1_LOAD_LOCATION:
            return { 
                ...state,
                location: {
                    ...state.location,
                    permission: action.permission,
                    location: action.location,
                }
            }
        case V1_SHOW_LOCATION:
            return {
                ...state,
                location_visible: action.visible
            }
        case V1_UPDATE_CACHE_REQUEST:
            return {
                ...state,
                cache_requests: state.cache_requests.filter(cr => cr.request_url != action.cache_request.request_url).concat(action.cache_request)
            }
        case V1_SHOW_AUTHENTICATE:
            return {
                ...state,
                show_authenticate: action.show_authenticate
            }
        case V1_SHOW_PROFILE:
            return {
                ...state,
                show_profile: action.show_profile
            }
        case V1_SHOW_WALLET:
            return {
                ...state,
                show_wallet: action.show_wallet
            }
        case V1_SHOW_CHECKOUT:
            return {
                ...state,
                show_checkout: action.show_checkout
            }
        case V1_AUTHENTICATE_APP:
            return {
                ...state,
                auth_tokens: { ...action.auth_tokens }
            }
        case V1_OBSERVE_APP:
            return {
                ...state,
                analytic_tokens: { ...action.analytic_tokens, set:true }
            }
        case V1_VIEW_PROMPT:
            let vp = state.viewed_prompts
            if(!vp){ vp = [] }
            return {
                ...state,
                viewed_prompts: vp.concat(action.id),
                help_soft_prompts: state.help_soft_prompts.filter(p => p.id != action.id)
            }
        case V1_VIEW_ALL_PROMPTS:
            let vps = state.viewed_prompts
            if(!vps){ vps = [] }
            return {
                ...state,
                help_soft_prompts: [],
                viewed_prompts: vps.concat(state.help_soft_prompts.map(p => p.id))
            }
        case V1_UPDATE_SESSION_ID:
            return {
                ...state,
                analytics_session_id: action.analytics_session_id
            }
        case V1_UPDATE_DISTINCT_ID:
            return {
                ...state,
                distinct_id: action.distinct_id
            }
        case V1_FULFILL_ALERT_CLOSED:
            return {
                ...state,
                fulfill_alert_closed: true
            }
        
        case V1_LOAD_HELP_SOFT_PROMPTS:
            return {
                ...state,
                help_soft_prompts: action.help_soft_prompts
            }
        case V1_UPDATE_BOTTOM_TAB_HEIGHT:
            return {
                ...state,
                bottom_tab_height: action.bottom_tab_height
            }
        case V1_UPDATE_DEFAULT_LINES_VISIBLE:
            return {
                ...state,
                default_lines_visible: action.default_lines_visible
            }
        case V1_UPDATE_WALLET_VISIBILE: 
            return {
                ...state,
                wallet_visible: action.visible
            }
        case V1_UPDATE_CATALOG_VISIBLE:
            return {
                ...state,
                catalog_visible: action.visible
            }
        case V1_UPDATE_ITEM_ORDER:
            return {
                ...state,
                item_order: action.item_order
            }
        case V1_UPDATE_HELMET: 
            return {
                ...state,
                helmet_state: {
                    ...action.helmet_state,
                    updated: state.helmet_state.updated += 1
                }
            }
        case V1_UPDATE_INITIAL_URL:
            return {
                ...state,
                initial_url: action.initial_url
            }
        case V1_UPDATE_APP_SIZE:
            return {
                ...state,
                app_size: action.app_size
            }
        case V1_UPDATE_AUDIO_HEIGHT:
            return {
                ...state,
                audio_height:action.audio_height
            }
        case V1_UPDATE_HEADER_HEIGHT:
            return {
                ...state,
                header_height: action.header_height
            }
        case V1_UPDATE_SOCKET_CONNECTED:
            return {
                ...state,
                socket_connected: action.connected
            }
        case V1_LOAD_APP_ALERTS:
            return {
                ...state,
                app_alerts_list:{
                    updated: state.app_alerts_list.updated + 1,
                    app_alerts: action.app_alerts
                }
            }
        case V1_LOAD_HELP: 
            return {
                ...state,
                help: {
                    initial_id: action.initial_id,
                    view_type: action.view_type,
                    help_prompt: action.help_prompt,
                    help_tour: action.help_tour,
                    tour_prompts: action.tour_prompts,
                    active_tour_prompt: action.active_tour_prompt
                }
            }
        case V1_CLEAR_HELP:
            return {
                ...state,
                help: undefined
            }
        case V1_SELECT_ACTION:
            return {
                ...state,
                help_action_select:{
                    client_native_id:action.client_native_id,
                    selected_action_type: action.action_type
                }
            }
        case V1_UPDATE_APP_ALERT:
            return {
                ...state,
                app_alerts_list: {
                    updated: state.app_alerts_list.updated + 1,
                    app_alerts: state.app_alerts_list.app_alerts.filter(al => al.app_alert_id != action.app_alert.app_alert_id).concat(action.app_alert)
                }
            }
        case V1_UPDATE_LAST_ROUTE:
            let last_route_key = state.current_route?.key
            let last_route_route = state.current_route?.route
            if(last_route_route && last_route_key && action.current_route.key == last_route_key){ return state }
            if(action.current_route.stack_key == state.current_route?.stack_key){
                return {
                    ...state,
                    last_route: undefined,
                    current_route: action.current_route
                }
            }
            return {
                ...state,
                last_route: state.current_route,
                current_route: action.current_route
            }
        case V1_UPDATE_INSTALL_PROMPT:
            return {
                ...state,
                deferred_prompt: action.deferred_prompt
            }
        default:
            return state
    }
 }
 
 export default utilReducer